import { useCallback, useEffect, useMemo, useState } from "react";
import { CellProps, Column, Row } from "react-table";
import { NotificationModule } from "ditmer-embla";
import Card from "@components/embla/card";
import { useLocalization } from "@components/localization/localizationProvider";
import Table from "@components/table/table";
import { useGetCaseParticipantsQuery, useRemoveParticipantMutation } from "@services/api/participant/caseParticipantApi";
import { CaseParticipantListModel } from "@services/api/participant/models/caseParticipantListModel";
import useDateFormatter from "src/hooks/useDateFormatter";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useAuth } from "@components/auth/authProvider";
import { AddButtonSlideInForm } from "@components/case/addButtonSlideInForm";
import ModalMessage from "@components/modal/modalMessage";
import { CaseRight } from "../../../infrastructure/CaseRight";
import { ParticipantBadge } from "../../../components/case/participant/participantBadge/participantBadge";
import useCaseRights from "../hooks/useCaseRights";
import useCaseAccess from "../hooks/useCaseAccess";
import { EditParticipant } from "./editParticipant/editParticipant";
import { AddParticipants } from "./addParticipants/addParticipants";

type ParticipantListProps = {
  caseId: string;
};

export const ParticipantList: React.FC<ParticipantListProps> = (props) => {
  const localizer = useLocalization();
  const dateFormatter = useDateFormatter();

  const { user: currentUser } = useAuth();
  const { isParticipant } = useCaseRights(props.caseId);
  const { canAddAndModifyUsers } = useCaseAccess(props.caseId);

  const { data: caseParticipants, isLoading: caseParticipantsIsLoading } = useGetCaseParticipantsQuery({ caseId: props.caseId });
  const [deleteParticipant, deleteRequest] = useRemoveParticipantMutation();
  const [deleteParticipantModel, setDeleteParticipantModel] = useState<string | undefined>(undefined);

  const [isLatestRemoveParticipantMutationFulfilled, setIsLatestRemoveParticipantMutationFulfilled] = useState<boolean>(false);

  useEffect(() => {
    if (deleteRequest.isUninitialized || isLatestRemoveParticipantMutationFulfilled) {
      return;
    }
    
    if (deleteRequest.isSuccess) {
      NotificationModule.showSuccessSmall(localizer.theParticipantWasRemoved());
      setIsLatestRemoveParticipantMutationFulfilled(true);
    }
  }, [deleteRequest.isSuccess, deleteRequest.isUninitialized, localizer, isLatestRemoveParticipantMutationFulfilled]);

  useEffect(() => {
    if (deleteRequest.isLoading) {
      setIsLatestRemoveParticipantMutationFulfilled(false);
    }
  }, [deleteRequest, deleteRequest.isLoading]);

  // Access checks

  const isNotSameParticipantOrIsAdmin = useCallback((participantId: string) => {
    return !isParticipant(participantId) || currentUser.isAdminAllCases();
  }, [currentUser, isParticipant]);

  const atleastOneOtherParticipantWithAccessRightAddUsers = useCallback((participantId: string) => {
    if (!caseParticipants)
      return false;
    return caseParticipants.filter((x) => x.id !== participantId).some((x) => x.caseAccessRights.includes(CaseRight.AddUsers));
  }, [caseParticipants]);

  // Define Columns
  const getColumns = useMemo(() => {

    const canDeleteParticipant = (participant: CaseParticipantListModel) => {

      // User rights checks:
      const userHasCorrectAccessRights = canAddAndModifyUsers();
      const isNotSameParticipant = isNotSameParticipantOrIsAdmin(participant.id);

      // Data validation access checks:
      const atleastOneParticipant = caseParticipants && caseParticipants?.length > 1;
      const otherParticipantWithAddUsers = atleastOneOtherParticipantWithAccessRightAddUsers(participant.id);

      return userHasCorrectAccessRights && atleastOneParticipant && otherParticipantWithAddUsers && isNotSameParticipant;
    };

    const onDeleteParticipantRequest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, caseParticipantId: string) => {
      //Disable the click specifically on the delete button, so that we dont need to have seperate colums for delete-button and expand-symbol.
      e.stopPropagation();
      setDeleteParticipantModel(caseParticipantId);
    };

    const onDeleteParticipant = (caseParticipantId: string) => {
      //Disable the click specifically on the delete button, so that we dont need to have seperate colums for delete-button and expand-symbol.
      deleteParticipant({ caseId: props.caseId, participantId: caseParticipantId }).unwrap();
      
    };

    const renderActionsColumn = (row: Row<CaseParticipantListModel>) => {
      return (
        <div className="d-flex margin-right-m">
          <button
            type="button"
            disabled={!canDeleteParticipant(row.original)}
            className="btn btn-danger btn-icon btn-outline"
            onClick={(e) => onDeleteParticipantRequest(e, row.original.id)}
          >
            <EmblaIcon iconName="delete" />
          </button>
          <ModalMessage
            open={!!deleteParticipantModel}
            onClose={() => setDeleteParticipantModel(undefined)}
            title={localizer.areYouSure()}
            description={localizer.removeParticipantFromCasePromt()}
            modalAcceptType="danger"
            acceptButtonText={localizer.delete()}
            acceptAction={() => deleteParticipantModel && onDeleteParticipant(deleteParticipantModel)}
          />
        </div>
        
      );
    };

    const columns: Column<CaseParticipantListModel>[] = [
      {
        accessor: "name",
        minWidth: 300,

        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellProps) => (
          <div className="d-flex flex-row">
            <ParticipantBadge
              name={cellProps.cell.value}
              initials={cellProps.row.original.initials}
              profilePictureUrl={cellProps.row.original.profilePictureUrl}
            />
          </div>
        ),
      },
      {
        accessor: "createdAt",
        minWidth: 300,

        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellProps) => (
          `${localizer.added()} ${dateFormatter.timeSince(cellProps.cell.value)} ${localizer.byLowerCase()} ${cellProps.row.original.createdByName}`
        ),
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        id: "actions",
        width: 20,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellProps: React.PropsWithChildren<CellProps<CaseParticipantListModel, string>>) => {
          const expandedIcon = cellProps.row.isExpanded ? <EmblaIcon iconName="arrow-down" /> : <EmblaIcon iconName="arrow-right" />;
          return <div className="d-flex justify-content-end align-items-center">
            {renderActionsColumn(cellProps.row)}
            {expandedIcon}
          </div>;
        },
      },
    ];
    return columns;
  }, [atleastOneOtherParticipantWithAccessRightAddUsers, canAddAndModifyUsers, caseParticipants, dateFormatter, deleteParticipant, deleteParticipantModel, isNotSameParticipantOrIsAdmin, localizer, props.caseId]);

  const renderEditForm = (rowData: CaseParticipantListModel) => (
    <EditParticipant
      caseId={props.caseId}
      participantId={rowData.id}
      userId={rowData.userId}
      caseAccessRights={rowData.caseAccessRights}
      isReadOnly={!canAddAndModifyUsers()}
      canChangeAddUsersAccessRight={atleastOneOtherParticipantWithAccessRightAddUsers(rowData.id) && isNotSameParticipantOrIsAdmin(rowData.id)}
    />
  );

  return (
    <>
      <Card className="mt-3">
        <h3 className="pl-2 pt-2 m-0">{localizer.participants()}</h3>
        <Table
          data={caseParticipants ?? []}
          isLoading={caseParticipantsIsLoading}
          columns={getColumns}
          noResultsOptions={{ noResultsMessage: localizer.noResultsFound() }}
          collapsableSubRowOptions={{ renderCollapsableRowComponent: renderEditForm }}
        />
      </Card>
      {canAddAndModifyUsers() &&
        <AddButtonSlideInForm>
          <AddParticipants caseId={props.caseId} />
        </AddButtonSlideInForm>
      }
    </>
  );
};
