import classNames from "classnames";
import { RoundBadge } from "@components/badge/roundBadge";
import styles from "./participantBadge.module.css";

type ParticipantBadgeProps = {
  name: string;
  initials?: string;
  profilePictureUrl?: string;
  badgeContainerClassNames?: string;
  nameFieldClassNames?: string;
  hideNameField?: boolean;
};

export const ParticipantBadge: React.FC<ParticipantBadgeProps> = (props) => {
  
  const initials = props.initials ? props.initials : props.name.split(" ").map((word) => word[0]).join("");

  return (
    <>
      <div className={classNames(styles.badgeContainer, props.badgeContainerClassNames)}>
        {props.profilePictureUrl ? <img className={styles.profilePicture} alt={"profilepicture"} src={props.profilePictureUrl} /> :
          <RoundBadge text={initials} smallText colorOverride={"secondary-light"}/>}
      </div>
      {!props.hideNameField &&
        <div className={classNames(styles.nameField, "margin-left-s", props.nameFieldClassNames)}>
          <div>
            {props.name}
          </div>
        </div>
      }
    </>
    
  );
};