import classNames from "classnames";
import { Row, TableOptions, TableState, useExpanded, useGlobalFilter, usePagination, useSortBy, useTable, useRowSelect } from "react-table";
import { Fragment, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLocalization } from "@components/localization/localizationProvider";
import { Pagination } from "@components/pagination/pagination";
import styles from "./baseTable.module.scss";
import { NoResultsMessage } from "./components/noResultsMessage";
import { TableBodyCenterContent } from "./components/tableBodyCenterContent";
import { PageSizeFilterOptions, SearchFilterOptions, TableFilterRow } from "./components/TableFilterRow";
import { TableHeader } from "./components/tableHeader";
import { TableRow } from "./components/tableRow";
import { DraggableTableRow } from "./components/draggableTableRow";
import TableBodyWithSpinner from "./components/TableSpinnerRow";

export type DefaultTableProps<TColumn extends object> = {
  rowOnClick?: (rowData: TColumn) => void;
  noResultsOptions?: NoResultsOptions;
} & Pick<TableOptions<TColumn>, "columns" | "data">;

export type NoResultsOptions = {
  noResultsMessage: string;
  noResultsIllustrationName?: string;
};

export interface ColumnSortInfo {
  columnName: string;
  direction: "asc" | "desc";
}

const dataHasId = (data: unknown): data is { id: string } => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (data as any).id !== undefined && (data as any).id !== null && typeof (data as any).id === "string";
};

export type FilterOptions = {
  // Page Size Filter:
  usePageSizeFilter: boolean;
  pageSizeOptions?: number[];
  onPageSizeChangedCallback?: (pageSize: number) => void;
  // Search Filter:
  useSearchQueryFilter: boolean;
  hideSearchLabel?: boolean;
  onSearchQueryChangedCallback?: (searchQuery: string) => void;
  extraFiltersHtml?: React.ReactNode
};

export type PaginationProps = {
  currentPage: number;
  pageSize: number;
  totalRecords: number;
  onPageChangeCallback: (page: number) => void;
};

export type DndOptions<T extends object> = {
  onDragDropRowCallback: (draggedRow: T, hoverIndex: number) => void;
  onDragDropPreviewCallback: (draggedIndex: number, hoverIndex: number) => void;
  disableDrag?: boolean;
};

export type ExpandingRowOptions<T extends object> = {
  renderExpandedSubRowComponent: (rowData: T) => JSX.Element;
  hideRowBorderTop?: boolean;
  expandOnClickRow?: boolean;
  defaultExpandIfLessThanCount?: number;
};

type BaseTableProps<TColumn extends object> = {
  noResultsOptions?: NoResultsOptions;
  isAsync?: boolean;
  isSortable?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  isPaginated?: boolean;
  /// Overrides defaultPageSize (100) if "paginationOverride.pageSize" is not defined (only use in synchronous tables)
  initialPageSize?: number;
  /// Has to be defined when "isAsync" and "isPaginated" is true
  paginationOverride?: PaginationProps;
  filterOptions?: FilterOptions
  rowOnClick?: (rowData: TColumn) => void;
  onSortCallback?: (sortInfos: ColumnSortInfo[]) => void;
  dndOptions?: DndOptions<TColumn>;
  expandRowOptions?: ExpandingRowOptions<TColumn>;
  initialSort?: ColumnSortInfo;
  additionalClasses?: string;
  onSelectedRowsChange?: (selectedRows: Row<TColumn>[]) => void;
  handleDelete?: (selectedRows: Row<TColumn>[]) => void;
} & DefaultTableProps<TColumn>;

const defaultPageSize = 10;

const BaseTable = <T extends object>({
  columns,
  data,
  noResultsOptions,
  isAsync = false,
  isSortable = false,
  isLoading = false,
  isFetching,
  isPaginated = false,
  initialPageSize,
  paginationOverride,
  filterOptions,
  rowOnClick,
  onSortCallback,
  dndOptions,
  expandRowOptions,
  initialSort,
  additionalClasses,
  onSelectedRowsChange,
  handleDelete,
}: BaseTableProps<T>) => {
  const localizer = useLocalization();
  const [ searchQuery, setSearchQuery ] = useState<string>("");

  const defaultExpanded: Record<string, boolean> = {};

  if (expandRowOptions?.defaultExpandIfLessThanCount && data.length < expandRowOptions.defaultExpandIfLessThanCount) {
    data.forEach((d, index) => {
      const rowId = dataHasId(d) ? d.id : index.toString();
      defaultExpanded[rowId] = true;
    });
  }

  const intialState: Partial<TableState<T>> = {
    pageSize: isPaginated ? (paginationOverride?.pageSize ?? initialPageSize ?? defaultPageSize) : defaultPageSize,
    pageIndex: (isPaginated ? (paginationOverride?.currentPage ?? 1) : 1) - 1,
    expanded: defaultExpanded,
    sortBy: initialSort ? [{ id: initialSort?.columnName, desc: initialSort.direction === "desc" ? true : false }] : [],
  };
  
  const asyncOptions: Partial<TableOptions<T>> = isAsync ? {
    manualPagination: true,
    manualSortBy: true,
    manualGlobalFilter: true,
    autoResetSortBy: false, // So that the columns still display correct sort-icon when refetching data
  } : {};

  const tableOptions: TableOptions<T> = {
    columns: columns,
    data: data,
    initialState: intialState,
    disableMultiSort: true,
    getRowId: (rowData, index) => {
      return dataHasId(rowData) ? rowData.id : index.toString();
    },
    ...asyncOptions,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    pageCount,
    setPageSize,
    setGlobalFilter,
    visibleColumns,
    state: { sortBy, pageIndex, pageSize },
    selectedFlatRows,
  } = useTable(tableOptions, useGlobalFilter, useSortBy, useExpanded, usePagination, useRowSelect);

  useEffect(() => {
    onSelectedRowsChange?.(selectedFlatRows);
  }, [onSelectedRowsChange, selectedFlatRows]);
  

  useEffect(() => {
    if (onSortCallback) {
      const columnSortInfos = sortBy.map((sortRule) => {
        return {
          columnName: sortRule.id,
          direction: sortRule.desc ? "desc" : "asc",
        } as ColumnSortInfo;
      });

      onSortCallback(columnSortInfos);
    }
  }, [onSortCallback, sortBy]);

  const noResults = rows.length <= 0;
  const rowsToRender = isPaginated && !isAsync ? page : rows;

  const renderFilterRow = (options: FilterOptions, columnsCount: number) => {

    if (!options.usePageSizeFilter && !options.useSearchQueryFilter && !options.extraFiltersHtml) {
      return;
    }

    const pageSizeFilterOptions: PageSizeFilterOptions | undefined = options.usePageSizeFilter ? {
      pageSize: pageSize,
      onPageSizeChanged: (newSize: number) => {
        setPageSize(newSize);

        if (options.onPageSizeChangedCallback) {
          options.onPageSizeChangedCallback(newSize);
        }
      },
      selectPageSizeLabel: localizer.show(),
      selectablePageSizes: options.pageSizeOptions,
    } : undefined;

    const searchFilterOptions: SearchFilterOptions | undefined = options.useSearchQueryFilter ? {
      searchQuery: searchQuery,
      onSearchChanged: (search: string) => {
        setSearchQuery(search);
        setGlobalFilter(search);

        if (filterOptions?.onSearchQueryChangedCallback) {
          filterOptions.onSearchQueryChangedCallback(search);
        }
      },
      searchLabel: !options.hideSearchLabel ? localizer.search() : undefined,
      searchPlaceholder: localizer.searchBarPlaceholder(),
    } : undefined;

    return (
      <tr>
        <th colSpan={columnsCount}>
          <TableFilterRow
            pageSizeFilterOptions={pageSizeFilterOptions}
            searchFilterOptions={searchFilterOptions}
          >
            {options.extraFiltersHtml}
          </TableFilterRow>
        </th>
      </tr>
    );
  };

  const onDragDropRow = (draggedRow: T, dropIndex: number) => {
    dndOptions?.onDragDropRowCallback(draggedRow, dropIndex);
  };

  const onPreviewDragDropRow = (dragIndex: number, dropIndex: number) => {
    dndOptions?.onDragDropPreviewCallback(dragIndex, dropIndex);
  };

  const renderTableRow = (row: Row<T>, index: number) => {
    const rowOnClickExpand = (rowData: T) => {
      row.toggleRowExpanded();
    };

    return (
      dndOptions && rows.length > 1
        ? <DraggableTableRow key={row.id} row={row} rowIndex={index} onClick={rowOnClick} onDropRow={onDragDropRow} onPreviewDropRow={onPreviewDragDropRow} disableDrag={dndOptions.disableDrag} />
        : <TableRow key={row.id} row={row} onClickCallback={expandRowOptions?.expandOnClickRow ? rowOnClickExpand : rowOnClick} />
    );
  };

  const renderExpandedTableRow = (row: Row<T>) => {
    if (!expandRowOptions) {
      return null;
    }

    return (
      <tr >
        <td role="cell" className={classNames(styles.expandedContent, expandRowOptions.hideRowBorderTop ? styles.expandedHideBorderTop : "")} colSpan={visibleColumns.length}>
          {expandRowOptions.renderExpandedSubRowComponent(row.original)}
        </td>
      </tr>
    );
  };

  const hideHeaderRow = columns.every((col) => col.Header === undefined);

  const table = (
    <div className="table-responsive">
      <div className={classNames("px-3 d-flex align-items-center", selectedFlatRows.length === 0 && styles.height0, styles.selectedActionRow)}>
        <div className={classNames("d-flex align-items-center gap-8", selectedFlatRows.length === 0 && "invisible" )}>
          <h5 className="selected-rows-text m-0">
            {selectedFlatRows.length} Selected documents
          </h5>
          <button className="btn btn-danger btn-delete-persons" onClick={() => handleDelete?.(selectedFlatRows)}
          >{localizer.delete()}</button>
        </div>
      </div>
      <table className={classNames("table", styles.table, additionalClasses)} {...getTableProps()}>
        <thead className="table-header">
          {filterOptions && renderFilterRow(filterOptions, columns.length)}
          {headerGroups.map((headerGroup) => (
            <tr hidden={hideHeaderRow || (noResults && !isLoading)} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                return <TableHeader key={i} header={column} isSortable={isSortable}/>;
              })}
            </tr>
          ))}
        </thead>
        <TableBodyWithSpinner
          showSpinner={isLoading || isFetching}
          colSpan={columns.length}
          {...getTableBodyProps()}
        >
          {noResultsOptions && noResults && !isLoading ? (
            <TableBodyCenterContent colSpan={columns.length}>
              <NoResultsMessage {...noResultsOptions} />
            </TableBodyCenterContent>
          ) : (
            rowsToRender.map((row, i) => {
              prepareRow(row);
              return (
                <Fragment key={row.id}>
                  {renderTableRow(row, i)}
                  {expandRowOptions && row.isExpanded && renderExpandedTableRow(row)}
                </Fragment>
              );
            })
          )}
        </TableBodyWithSpinner>
      </table>
      {isPaginated && !noResults &&
        <Pagination
          handlePagination={(pageNumber) => {
            const index = pageNumber - 1;
            gotoPage(index);

            if (paginationOverride) {
              paginationOverride.onPageChangeCallback(pageNumber);
            }
          }}
          currentPage={paginationOverride ? paginationOverride.currentPage : pageIndex + 1}
          totalPages={paginationOverride ? Math.ceil(paginationOverride.totalRecords / paginationOverride.pageSize) : pageCount}
        />
      }
    </div>
  );

  if (dndOptions) {
    return (
      <DndProvider backend={HTML5Backend}>
        {table}
      </DndProvider>
    );
  }

  return table;
};

export default BaseTable;
