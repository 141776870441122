import classNames from "classnames";
import { NotificationModule } from "ditmer-embla";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "@components/auth/authProvider";
import { useLocalization } from "@components/localization/localizationProvider";
import { Dimensions } from "@components/resizable/resizable";
import { Spinner } from "@components/spinner/spinner";
import { CasePresentationEventModel } from "@pages/pdfviewer/component/models/casePresentationEventModel";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { TempMarkingAction } from "@pages/pdfviewer/component/models/tempMarkingAction";
import { createHighlightMarking } from "@pages/pdfviewer/component/page/canvas/utils/createMarking";
import { resetActiveTool, setActiveTool } from "@pages/pdfviewer/component/pdfViewerSlice";
import usePdfViewer from "@pages/present/usePdfViewer";
import usePresentPresentationDocument from "@pages/present/usePresentPresentationDocument";
import { HubConnectionType } from "@services/signalRClient/hubConnectionFactory";
import { PresentationHubEventType } from "@services/signalRClient/presentationHubConnectionManager";
import useBroadcastChannel from "src/hooks/useBroadcastChannel";
import useHubConnection from "src/hooks/useHubConnection";
import Button from "@components/embla/button";
import { EmblaIcon } from "@components/embla/emblaIcon";
import ModalMessage from "@components/modal/modalMessage";
import PresentBottomBar from "@pages/present/presentBottomBar";
import { apiTags } from "@services/api/baseApi";
import presentationApi from "@services/api/presentation/presentationApi";
import { RoutePaths } from "@components/routing/routes";
import usePageMarkings from "@pages/pdfviewer/component/page/canvas/usePageMarkings";
import SinglePagePdfView, { Position } from "../pdfviewer/component/singlePagePdfView";
import useTempMarkings from "./hooks/useTempMarkings";
import LiveZoom from "./liveZoom";
import styles from "./present.module.scss";
import PresentSidebar from "./presentSidebar";
import { PRESENTATION_BROADCAST_CHANNEL, PresentationAction, PresentationActionType } from "./presentationBroadcastActions";
import { clearPausedPresentationState, clearTempPresentationPageState, pausedPresentationStateSelector, setPausedPresentationState, tempPresentationPageStateSelector } from "./presentationSlice";
import PresenterToolbox, { PresenterTool } from "./presenterToolbox";
import useDynamicPresentationPages from "./useDynamicPresentationPages";
import TempPresentationPageOverlay from "./tempPresentationPageOverlay";
import usePresentCaseDocument from "./usePresentCaseDocument";
import LocatePagePopover from "./LocatePagePopover";
import InfoHeader from "./InfoHeader/InfoHeader";


const Present = () => {
  const { presentationId, ...params } = useParams();
  let pageIndex = parseInt(params.pageIndex ?? "");
  if (!presentationId || Number.isNaN(pageIndex)) {
    throw Error("presentationId and pageIndex params are required");
  }

  const [caseHubConnectionManager] = useState(useHubConnection(HubConnectionType.Presentation));
  const tempPresentationState = useSelector(tempPresentationPageStateSelector);
  const [stateToRestore] = useState(useSelector(pausedPresentationStateSelector)); // wrap in useState to take only on first render
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localization = useLocalization();
  const [showMarkings, setShowMarkings] = useState(false);
  const [showSearchPopover, setShowSearchPopover] = useState(false);
  const [showSpeakerNotes, setShowSpeakerNotes] = useState(true);
  const { title, isLoading, pdfUrl } = usePresentPresentationDocument(presentationId);
  const { pagesData, isPagesLoading, markingsOverride, setPageMarkingsOverride } = useDynamicPresentationPages(presentationId, stateToRestore?.markingsOverride);
  if (pagesData && pageIndex >= pagesData.length) {
    pageIndex = pagesData.length - 1;
  }

  const { markings: allPageMarkings } = usePageMarkings(pageIndex);
  const pageHasMarkings = allPageMarkings.length > 0;

  const { isLoading: isLoadingTempUrl, pdfUrl: pdfUrlTemp } = usePresentCaseDocument(tempPresentationState?.documentId, tempPresentationState?.pageIndex);
  const { handleLoadSuccess } = usePdfViewer(presentationId);
  const [tool, setTool] = useState<PresenterTool>(PresenterTool.Cursor);
  const { user } = useAuth();
  const localizer = useLocalization();


  const [closePresentationModalMessageOpen, setClosePresentationModalMessageOpen] = useState(false);

  const closePresentation = () => {
    if (pagesData && pagesData[0]) {
      navigate(RoutePaths.casePresentationEdit.url(pagesData[0].caseId, presentationId, pagesData[0].id));
    } else {
      navigate(-1);
    }
    postMessage({ type: PresentationActionType.Close });
    dispatch(clearTempPresentationPageState());
  };

  const { postMessage } = useBroadcastChannel<PresentationAction>({ name: PRESENTATION_BROADCAST_CHANNEL });

  const { tempMarkings, handleTempMarkingAction } = useTempMarkings({ pageIndex });
  const onTempMarkingAction = (action: TempMarkingAction) => {
    handleTempMarkingAction(action);
    postMessage({ type: PresentationActionType.TempMarkingChange, action });
  };

  useEffect(() => {
    return () => {
      if (presentationId && caseHubConnectionManager) {
        caseHubConnectionManager.stopConnection(presentationId);
      }
    };
  }, [caseHubConnectionManager, dispatch, presentationId]);

  useEffect(() => {
    if (tempPresentationState) {
      setShowSpeakerNotes(false);
    } else {
      setShowSpeakerNotes(true);
    }
  }, [tempPresentationState]);

  useEffect(() => {
    caseHubConnectionManager.startConnection(presentationId);
    return () => {
      caseHubConnectionManager.stopConnection(presentationId);
    };
  }, [caseHubConnectionManager, presentationId]);


  const HandlePresentationUpdate = useCallback((e: CasePresentationEventModel) => {
    dispatch(presentationApi.util.invalidateTags([
      { type: apiTags.casePresentation, id: e.presentationId },
      apiTags.casePresentationPdfFilesUrl,
    ]));

    postMessage({ type: PresentationActionType.Refresh });

    NotificationModule.showInfo("", localization.presentationUpdated());
  }, [dispatch, localization, postMessage]);

  useEffect(() => {
    caseHubConnectionManager.on(
      presentationId,
      PresentationHubEventType.PresentationEvent,
      HandlePresentationUpdate,
    );

    return () => {
      caseHubConnectionManager.off(presentationId, PresentationHubEventType.PresentationEvent, HandlePresentationUpdate);
    };
  }, [HandlePresentationUpdate, caseHubConnectionManager, localization, presentationId]);


  useEffect(() => {
    if (stateToRestore) {
      dispatch(clearPausedPresentationState());
      postMessage({ type: PresentationActionType.Refresh });
    }
  }, [dispatch, postMessage, stateToRestore]);

  const handleMarkingsChange = (markingIds: string[]) => {
    setPageMarkingsOverride(pageIndex, markingIds);
    postMessage({ type: PresentationActionType.MarkingsChange, markingIds, pageIndex });
  };

  const pausePresentation = () => {
    if (title && pagesData) {
      dispatch(setPausedPresentationState({
        presentationId,
        pageIndex,
        pageId: pagesData[pageIndex].id,
        title,
        markingsOverride,
      }));
      navigate(-1);
    }
  };

  const changeZoom = useCallback((area?: Dimensions) => {
    postMessage({ type: PresentationActionType.ZoomChange, area });
  }, [postMessage]);

  const showPointer = useCallback((position?: Position) => {
    postMessage({ type: PresentationActionType.PointerPositionChange, position });
  }, [postMessage]);

  const handlePointerPositionChange = (position?: Position) => {
    if (tool === PresenterTool.Pointer) {
      showPointer(position);
    }
  };

  useEffect(() => {
    switch (tool) {
      case PresenterTool.Highlight:
        dispatch(setActiveTool({ type: MarkingType.Highlight, template: createHighlightMarking({ user, isTemporary: true }) }));
        return () => dispatch(resetActiveTool());
      case PresenterTool.Zoom:
        return () => changeZoom(undefined);
      case PresenterTool.Pointer:
        return () => showPointer(undefined);
    }
  }, [dispatch, changeZoom, showPointer, tool, user]);

  if (isLoading || isPagesLoading || isLoadingTempUrl) {
    return <Spinner className="h-100vh" />;
  }


  return (
    <>
      <div className="h-100vh row w-100 m-0 overflow-hidden">
        <div className={classNames("h-100 d-flex flex-column p-0", showSpeakerNotes ? "col-8" : "col-12")}>
          <div className="h-100 d-flex flex-column">
            {pdfUrl && pagesData &&
              <div className="flex-1 container-fluid overflow-hidden p-0">
                <div className="h-100 overflow-hidden d-none d-sm-block flex-shrink-0">
                  {tempPresentationState && pdfUrlTemp ?
                    <SinglePagePdfView
                      pdfUrl={pdfUrlTemp}
                      pdfPageIndex={0}
                      pageMargins={10}
                      disableEditing
                      hideCards
                      presentedMarkings={[]}
                      hidePresentationBtn
                      onPointerPositionChange={handlePointerPositionChange}
                      pageOverlay={(scale) => tool === PresenterTool.Zoom && (
                        <LiveZoom
                          scale={scale}
                          onZoomSelect={changeZoom}
                        />
                      )}
                    />
                    :
                    <SinglePagePdfView
                      pdfUrl={pdfUrl}
                      pdfPageIndex={pageIndex}
                      onDocumentLoadSuccess={handleLoadSuccess}
                      hideCards={!showMarkings && pageHasMarkings}
                      pageMargins={10}
                      disableEditing
                      hidePresentationBtn
                      orientation={pagesData[pageIndex]?.orientation}
                      onPointerPositionChange={handlePointerPositionChange}
                      pageOverlay={(scale) => tool === PresenterTool.Zoom && (
                        <LiveZoom
                          scale={scale}
                          onZoomSelect={changeZoom}
                        />
                      )}
                      presentedMarkings={pagesData[pageIndex]?.markingIds}
                      onPresentationMarkingsChange={handleMarkingsChange}
                      tempMarkings={tempMarkings}
                      onTempMarkingAction={onTempMarkingAction}
                      //pageHeader={<InfoHeader title={pagesData[pageIndex].fileName}/>}
                    />
                  }
                  <div className="d-flex align-items-start w-100 gap-s">
                    <ModalMessage
                      open={closePresentationModalMessageOpen}
                      onClose={() => setClosePresentationModalMessageOpen(false)}
                      title={localizer.areYouSure()}
                      description={localizer.areYouSurePresentationClose()}
                      modalAcceptType="danger"
                      acceptButtonText={localizer.close()}
                      acceptAction={() => closePresentationModalMessageOpen && closePresentation()}
                    />
                  </div>

                  <PresenterToolbox
                    className={styles.toolbox}
                    isShowingTempPage={Boolean(tempPresentationState)}
                    activeTool={tool}
                    onToolChange={setTool}
                  />
                </div>
              </div>
            }
          </div>
          {pagesData && !tempPresentationState &&
            <div className={classNames("w-100 m-0 border-top padding-s")}>
              <PresentBottomBar
                currentPageIndex={pageIndex}
                pagesData={pagesData}
                continueOnPage={stateToRestore?.pageId}
                searchPagePopoverOpen={showSearchPopover}
                showSpeakerNotesCallback={(x) => setShowSpeakerNotes(x)}
                showMarkingsCallback={(x) => setShowMarkings(x)}
                showPageSearchPopoverCallback={() => setShowSearchPopover(true)}
              />
            </div>
          }
        </div>
        {pagesData && showSpeakerNotes &&
          <div className={classNames(styles.sidebar, "col-4 p-0 h-100 border-left ")}>
            <PresentSidebar
              presentationId={presentationId}
              pagesData={pagesData}
              currentPageIndex={pageIndex}
            />
          </div>
        }
      </div >
      <div className={classNames(styles.actionTopBar, "d-flex gap-s")}>
        <Button
          iconBtn
          className={styles.topButton}
          onClick={() => setClosePresentationModalMessageOpen(true)}
        >
          <EmblaIcon iconName="close" />
        </Button>
        <Button
          iconBtn
          className={styles.topButton}
          onClick={pausePresentation}
        >
          <EmblaIcon iconName="minus" />
        </Button>
      </div>
      {pagesData &&
          <LocatePagePopover open={showSearchPopover} caseId={pagesData[0].caseId} onClosePopoverClicked={() => setShowSearchPopover(false)}/>
      }
      <TempPresentationPageOverlay newSearchClicked={() => setShowSearchPopover(true)} />
    </>
  );
};

export default Present;
