export interface Config {
  apiUrl: string;
  fileApiUrl: string;
  sentryDsn: string;
  environment: string;
  idpUrl: string;
  idpClientId: string;
  contactSupportEmail: string;
  baseUrl: string;
  appProtocol: string;
  version : string;
}

export class ConfigManager {
  private static config: Config;

  public loadConfig() {
    if (ConfigManager.config) {
      return Promise.resolve(ConfigManager.config);
    }

    return fetch(`${process.env.PUBLIC_URL}/config.json`)
      .then((result) => result.json() as Promise<Config>)
      .then((loadedConfig) => {
        ConfigManager.config = loadedConfig;
        return ConfigManager.config;
      });
  }
}
