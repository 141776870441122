import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "@hooks";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { pdfTypeIdSelector, pdfTypeSelector } from "../../pdfViewerSlice";
import { useGetMarkingsQuery } from "../../markingsApi";

const usePageMarkings = (pageIndex: number, employeeIds?: string[], markingTypes?: MarkingType[]) => {

  const pdfTypeId = useAppSelector(pdfTypeIdSelector);
  const pdfType = useAppSelector(pdfTypeSelector);

  const { data, isLoading, isFetching } = useGetMarkingsQuery((pdfTypeId && pdfType) ? { pdfTypeId: pdfTypeId, origin: pdfType } : skipToken);

  const markings = useMemo(() => {
    let pageMarkings = data?.[pageIndex] ?? [];

    //filter markings
    if (markingTypes)
      pageMarkings = pageMarkings.filter((x) => markingTypes.includes(x.type));

    //filter owners
    if (employeeIds)
      pageMarkings = pageMarkings.filter((x) => employeeIds.includes(x.owner.employeeId));

    return pageMarkings;
  }, [data, employeeIds, markingTypes, pageIndex]);

  return { markings, isLoading, isFetching };
};

export default usePageMarkings;
