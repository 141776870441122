import classNames from "classnames";
import { FieldValues } from "react-hook-form";
import { FormControlProps } from "@components/forms/FormControlProps.model";
import FormRadio from "@components/forms/FormRadio";
import styles from "./radioGroup.module.scss";

interface ColorSelectionProps {
  colors: string[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ColorSelection = <T extends FieldValues = any>({
  id,
  formGroupClassName,
  methods,
  colors,
  name,
}: FormControlProps<T, ColorSelectionProps>) => {
  return (
    <div className={classNames(styles.radioGroup, formGroupClassName)}>
      {colors.map((color) =>
        <label key={color}>
          <FormRadio
            methods={methods}
            id={`${id}-${color}`}
            name={name}
            value={color}
          >
            <div
              className={classNames(styles.radio, "rounded-circle")}
              style={{ backgroundColor: color }}
            />
          </FormRadio>
        </label>,
      )}
    </div>
  );
};

export default ColorSelection;
