import classNames from "classnames";
import { ReorderExtractDraftSectionDocument } from "@pages/extractCompositionPage/hooks/useDragDropSectionDocumentReorder";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";
import styles from "./DragPreviewSingleDocument.module.scss";

type DragPreviewSingleDocumentProps = {
  document: CaseDocumentModel | ReorderExtractDraftSectionDocument;
};

const DragPreviewSingleDocument = ({ document }: DragPreviewSingleDocumentProps) => {
  const getDocumentName = () => {
    if ("documentName" in document) {
      return document.documentName;
    } else {
      return document.document.caseDocumentName;
    }
  };


  return <div className={classNames("px-3 py-1", styles.container)}>
    <p className={styles["margin-0"]}>
      {getDocumentName()}
    </p>
  </div>;
};

export default DragPreviewSingleDocument;