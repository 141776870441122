import { useEffect, useState } from "react";
import classNames from "classnames";
import { useLocalization } from "@components/localization/localizationProvider";
import {
  PRESENTATION_BROADCAST_CHANNEL,
  PresentationAction,
  PresentationActionType,
} from "@pages/present/presentationBroadcastActions";
import usePresentNavigation from "@pages/present/usePresentNavigation";
import useContinueOnPage from "@pages/present/useContinueOnPage";
import Button from "@components/embla/button";
import { CasePresentationPageModel } from "@services/api/case/models/casePresentationPageModel";
import { ReactComponent as NextPageActive } from "@content/icons/nextpage-active.svg";
import { EmblaIcon } from "@components/embla/emblaIcon";
import ExpandingDropdownMenu from "@components/expandingDropdownMenu/expandingDropdownMenu";
import ThumbnailContainer from "@pages/pdfviewer/component/thumbnail/thumbnailContainer";
import InlineSwitch from "@components/checkbox/inlineSwitch";
import useLocalStorage from "src/hooks/useLocalStorage";
import useBroadcastChannel from "../../hooks/useBroadcastChannel";
import styles from "./presentBottomBar.module.scss";

interface PresentBottomBarProps {
  pagesData: CasePresentationPageModel[];
  currentPageIndex: number;
  continueOnPage?: string;
  searchPagePopoverOpen: boolean;
  showSpeakerNotesCallback?: (showSpeakerNotes: boolean) => void;
  showMarkingsCallback?: (showMarkings: boolean) => void;
  showPageSearchPopoverCallback?: () => void;
}

const PresentBottomBar = ({
  pagesData,
  currentPageIndex,
  continueOnPage,
  searchPagePopoverOpen,
  showSpeakerNotesCallback,
  showMarkingsCallback,
  showPageSearchPopoverCallback,
}: PresentBottomBarProps) => {
  const localizer = useLocalization();

  const totalPages = pagesData.length;
  const currentPage = pagesData[currentPageIndex];

  const [expanded, setExpanded] = useState(false);
  const [showSpeakerNotesState, setShowSpeakerNotesState ] = useLocalStorage("showSpeakerNotesState", currentPage.notes.length > 0);
  const [showMarkingsState, setShowMarkingsState] = useLocalStorage("showMarkingState", currentPage.hasDocumentMarkings);
  const { postMessage } = useBroadcastChannel<PresentationAction>({
    name: PRESENTATION_BROADCAST_CHANNEL,
    onMessage: ({ data: action }) => {
      if (action.type === PresentationActionType.PageChange) {
        goToPage(action.pageIndex, false);
      }
    },
  });
  const {
    canGoBack,
    goBack,
    canGoForward,
    goForward,
    goToPage,
  } = usePresentNavigation({ currentPageIndex, totalPages, broadcastPageChange: postMessage, pageSearchPopoverOpen: searchPagePopoverOpen });

  useContinueOnPage({ continueOnPage, currentPageIndex, pagesData, goToPage });

  useEffect(() => showSpeakerNotesCallback?.(showSpeakerNotesState), [showSpeakerNotesCallback, showSpeakerNotesState]);
  useEffect(() => showMarkingsCallback?.(showMarkingsState), [showMarkingsCallback, showMarkingsState]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Button
            linkBtn
            iconBtn
            disabled={!canGoBack}
            onClick={goBack}
            borderless
          >
            <NextPageActive className={classNames(styles.rotate, styles.navigationButton)} />
          </Button>
          <Button theme="default" className="d-flex align-items-center pl-3 pr-2" onClick={() => setExpanded((x) => !x)}>
            {localizer.page()} {currentPageIndex + 1} {localizer.pdfToolbarOf()} {totalPages}
            <EmblaIcon additionalClasses="ml-2" iconName={expanded ? "arrow-down" : "arrow-up"} />
          </Button>
          <Button
            linkBtn
            iconBtn
            disabled={!canGoForward}
            onClick={goForward}
            borderless
          >
            <NextPageActive className={classNames(styles.navigationButton)}/>
          </Button>
          <Button
            className={classNames("btn btn-default")}
            onClick={showPageSearchPopoverCallback && (() => showPageSearchPopoverCallback())}
          >
            <EmblaIcon iconName={"document-view"} />
            {localizer.browsePage()}
          </Button>
        </div>
        <div className="d-flex align-items-center">
          <ExpandingDropdownMenu
            menuDropdownPlacement={"top-start"}
            triggeredBy={"leftclick"}
            buttonNode={
              <Button className="mr-2">
                <EmblaIcon iconName={"preferences"} />
                {localizer.settings()}
              </Button>
            }>
            <ExpandingDropdownMenu.EmptyItem className="p-2">
              <InlineSwitch id="presentShowSpeakerNotes" checked={showSpeakerNotesState} onChange={() => setShowSpeakerNotesState((x) => !x)}/>
              <label htmlFor="presentShowSpeakerNotes">{localizer.show()} <span className="text-lowercase">{localizer.speakerNotes()}</span></label>
            </ExpandingDropdownMenu.EmptyItem>
            <ExpandingDropdownMenu.EmptyItem className="p-2">
              <InlineSwitch id="presentShowMarkings" checked={showMarkingsState} onChange={() => setShowMarkingsState((x) => !x)}/>
              <label htmlFor="presentShowMarkings">{localizer.show()} <span className="text-lowercase">{localizer.markings()}</span></label>
            </ExpandingDropdownMenu.EmptyItem>
          </ExpandingDropdownMenu>
        </div>
      </div>
      {expanded &&
        <div className="d-flex gap-s mt-2 overflow-auto scrollbar">
          {pagesData?.map((page, index) => {
            const pageTitle = page.titlePageTitle?.length ? page.titlePageTitle : `${localizer.pdfPage()} ${page.pageIndex + 1}`;
            return (
              <div className={styles.pageContainer} key={page.id} onClick={() => goToPage(index)}>
                <div className="d-flex flex-column align-items-center">
                  <ThumbnailContainer
                    srcUrl={page.thumbnailUrl}
                    fileName={page.fileName}
                    title={page.isTitlePage ? pageTitle : page.fileName}
                    overlayIcons={page.overlayIconTypes}
                    active={currentPage.id === page.id}
                  />

                  <p className={classNames("small margin-top-xs margin-bottom-0", styles.pageTitle)} title={pageTitle}>{page.isTitlePage ? localizer.presentationTitlePage() : pageTitle}</p>
                </div>
              </div>
            );
          })}
        </div>
      }
    </div>
  );
};

export default PresentBottomBar;
