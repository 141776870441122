import { useParams } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import { MarkingTag } from "@services/api/sharedModels/markingTagModel";
import { FormControlProps } from "@components/forms/FormControlProps.model";
import FormDropdown from "@components/forms/FormDropdown";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import { useLocalization } from "@components/localization/localizationProvider";
import { useGetCaseMarkingTagsQuery } from "@services/api/case/caseApi";


// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MarkingTagsDropdownProps<TFieldValues extends FieldValues> = FormControlProps<TFieldValues, any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MarkingTagsDropdown = <TMarkingModel extends MarkingModel = any >({
  methods,
  id,
  name,
}: MarkingTagsDropdownProps<TMarkingModel>) => {
  const { caseId } = useParams();
  const localizer = useLocalization();

  if (!caseId) {
    throw new Error("Param \"caseId\" is required");
  }

  const { data: markingTags } = useGetCaseMarkingTagsQuery({ caseId: caseId });

  return (
    <FormDropdown
      methods={methods}
      id={id}
      name={name}
      label={localizer.tags()}
      placeholder={localizer.createTags()}
      isMulti
      isClearable={false}
      allowCreate
      colorAsSubjects
      options={markingTags?.map((tag) => ({ label: tag.tagName, value: tag }))}
      getOptionValue={(option) => option.value.tagName}
      valueToOptionTransform={(value: MarkingTag) => value ? { label: value.tagName, value: value } : null}
      getNewOptionData={(val: string) => ({ label: val, value: { tagName: val } })}
    />
  );
};

export default MarkingTagsDropdown;
