import * as Sentry from "@sentry/react";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Enviroment } from "@components/badge/enviromentBadge";
import { Config } from "@config/configManager";

// eslint-disable-next-line @typescript-eslint/naming-convention
const SentryErrorContext = createContext<string | undefined>(undefined);

export const SentryErrorProvider = ( props : { children : ReactNode, sentryErrorKey : undefined | string }) => {
  return (
    <SentryErrorContext.Provider value={props.sentryErrorKey}>
      {props.children}
    </SentryErrorContext.Provider>
  );
};

export const useSentryErrorCode = () => {
  return useContext(SentryErrorContext);
};

export const useSentry = (config?: Config) => {
  const [sentryErrorKey, setSentryErrorKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (config && config.environment !== Enviroment.LOCALDEV) {
      Sentry.init({
        beforeSend: (event, hint) => {
          setSentryErrorKey(event.event_id);
          return event;
        },
        dsn: config.sentryDsn,
        environment: config.environment,
        release: config.version,
        normalizeDepth: 5,
      });
    }
  }, [config]);

  return sentryErrorKey;
};
