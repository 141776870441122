import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import { UserInfoModel } from "@models/userInfoModel";
import type { AppThunkAction, RootState } from "@app";

export interface AuthState {
  token: string | null;
  user: UserInfoModel | null;
}

const initialState: AuthState = {
  token: null,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUserToState: (state, action: PayloadAction<UserInfoModel>) => {
      state.user = action.payload;
    },
    clearAuthState: (state) => {
      state.token = null;
      state.user = null;
    },
  },
});

export const setUser = (user: UserInfoModel): AppThunkAction => (dispatch) => {
  Sentry.setUser({
    id: user.id,
    email: user.email,
    username: user.name,
  });
  dispatch(authSlice.actions.setUserToState(user));
};

export const removeAuth = (user: UserInfoModel): AppThunkAction => (dispatch) => {
  Sentry.setUser(null);
  dispatch(authSlice.actions.clearAuthState());
};

export const { setToken } = authSlice.actions;
export const authStateSelector = (state: RootState) => state.auth;
export const userSelector = (state: RootState) => state.auth.user;
export default authSlice.reducer;
