import classNames from "classnames";
import { EmblaIcon } from "@components/embla/emblaIcon";
import styles from "./DragPreviewMultipleDocument.module.scss";

type DragPreviewMultipleDocumentProps = {
  documentsAmount: number;
};

const DragPreviewMultipleDocument = ({ documentsAmount } : DragPreviewMultipleDocumentProps) => {
  return (
    <div className={classNames("flex row gap-8 px-3 py-1", styles.container)} >
      <EmblaIcon iconName="document"/>
      <p className={styles["margin-0"]}>
        <b>
          {documentsAmount} Documents
        </b>
      </p>
    </div>
  );
};

export default DragPreviewMultipleDocument;