import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "@components/auth/authProvider";
import { setToken } from "@components/auth/authSlice";
import { SessionStorageKeys } from "@infrastructure/sessionStorageKeys";
import { useAppDispatch } from "@hooks";
import { Spinner } from "@components/spinner/spinner";
import { RoutePaths } from "../../components/routing/routes";

 
export const AuthRedirectPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userService, user } = useAuth();
  const [signinCallbackReceived, setSigninCallbackReceived] = useState(false);

  useEffect(() => {
    if (!signinCallbackReceived) {
      userService.waitForSignIn()
        .then((token) => {
          setSigninCallbackReceived(true);
          dispatch(setToken(token));
        }).catch((x: Error) => {
          console.log(x);
          // ignore error that occurs in development, because the SPA is running in http.
        });
    }
  }, [dispatch, navigate, userService, signinCallbackReceived]);

  useEffect(() => {
    if (signinCallbackReceived && user.authenticated) {
      console.log("User signed in, redirecting");

      const redirectTo = sessionStorage.getItem(SessionStorageKeys.returnUrl);
      navigate(redirectTo ?? RoutePaths.index.path);
    }
  }, [navigate, signinCallbackReceived, user.authenticated]);

  return (
    <Spinner />
  );
};
