import { forwardRef, HTMLAttributes, ReactNode, Ref } from "react";
import Card from "@components/embla/card";
import { MarkingModel } from "../../../models/markingModel";
import { MarkingType } from "../../../models/markingType";
import FocusBoxCardContent from "../focusBox/focusBoxCardContent";
import HighlightCardContent from "../highlight/highlightCardContent";
import VerticalLinesCardContent from "../verticalLines/verticalLinesCardContent";
import CommentCardContent from "../comment/commentCardContent";
import CardProps from "./cardProps.model";

type MarkingCardProps = CardProps<MarkingModel> & HTMLAttributes<HTMLDivElement>;

const MarkingCard = forwardRef(({
  marking,
  activeState,
  isPresentationEdit,
  onMarkingChange,
  onSave,
  onReplyCreateEdit,
  onDelete,
  onClose,
  editMarkingClicked,
  toggleMarkingClicked,
  toggleMarkingState,
  isTemplate,
  className,
  disableCheckbox,
  ...cardProps
}: MarkingCardProps, ref: Ref<HTMLDivElement>) => {
  const contentProps = {
    activeState,
    isPresentationEdit,
    onMarkingChange,
    onSave,
    editMarkingClicked,
    toggleMarkingClicked,
    toggleMarkingState,
    onReplyCreateEdit,
    onDelete,
    onClose,
    isTemplate,
  };
  let cardContent: ReactNode;
  switch (marking.type) {
    case MarkingType.Comment:
      cardContent = (
        <CommentCardContent marking={marking} {...contentProps} />
      );
      break;
    case MarkingType.Highlight:
      cardContent = (
        <HighlightCardContent disableCheckbox={disableCheckbox} marking={marking} {...contentProps} />
      );
      break;
    case MarkingType.VerticalLines:
      cardContent = (
        <VerticalLinesCardContent disableCheckbox={disableCheckbox} marking={marking} {...contentProps} />
      );
      break;
    case MarkingType.FocusBox:
      cardContent = (
        <FocusBoxCardContent disableCheckbox={disableCheckbox} marking={marking} {...contentProps} />
      );
      break;
    default:
      return null; // return null right away to not render empty card
  }

  return (
    <Card
      ref={ref}
      className={className}
      {...cardProps}
    >
      {cardContent}
    </Card>
  );
});

export default MarkingCard;

