import classNames from "classnames";
import { ParticipantBadge } from "@components/case/participant/participantBadge/participantBadge";
import ClampedParagraph from "@components/clampedParagraph/clampedParagraph";
import { useLocalization } from "@components/localization/localizationProvider";
import DropdownMenu from "@components/menuButton/dropdownMenu";
import { MenuDotButton } from "@components/menuButton/dropdownMenuItems/menuDotButton";
import { CaseBaseReturnModel } from "@services/api/case/models/caseBaseReturnModel";
import useDateFormatter from "src/hooks/useDateFormatter";
import { IconTextCallbackItem } from "@components/menuButton/dropdownMenuItems/IconTextDropdownItem";
import { EmblaIcon } from "@components/embla/emblaIcon";
import Card from "@components/embla/card";
import styles from "./CaseInfo.module.scss";

type CaseInfoProps = {
  caseModel: CaseBaseReturnModel;
  setEditModalOpen: (value: boolean) => void;
  setDeleteModalOpen: (value: boolean) => void;
};

const CaseInfo = ({
  caseModel,
  setDeleteModalOpen,
  setEditModalOpen,
}: CaseInfoProps) => {
  const localizer = useLocalization();
  const dateFormatter = useDateFormatter();

  return (
    <Card className={classNames(styles.card, "p-2 mb-0")} >
      <div
        className={classNames(
          "d-flex justify-content-between align-items-start px-2 pt-1 gap-s",
          styles.caseInfoContainer,
        )}
      >
        <h3 className={classNames("m-0", styles.caseTitle)}>
          {caseModel.title}
        </h3>
        <div className="d-flex px-1 flex-wrap">
          {caseModel.caseParticipants.map((participant) => (
            <ParticipantBadge
              key={participant.id}
              name={participant.name}
              initials={participant.initials}
              profilePictureUrl={participant.profilePictureUrl}
              nameFieldClassNames="m-0"
              hideNameField={true}
            />
          ))}
        </div>
        <div className="d-flex align-items-center flex-wrap">
          <p className="text-nowrap m-0 pr-1">
            {localizer.creationDate() + ":"}
          </p>
          <p className="m-0">{dateFormatter.date(caseModel.creationDate)}</p>
        </div>
        <div className="d-flex align-items-center flex-wrap">
          <p className="text-nowrap m-0 pr-1">
            {localizer.courtCaseNumber() + ":"}
          </p>
          <p className="m-0">{caseModel.courtCaseNumber}</p>
        </div>
        <div className="d-flex align-items-center flex-wrap">
          <p className="text-nowrap m-0 pr-1">{localizer.status() + ":"}</p>
          <p className="m-0">{caseModel.statusDisplayString}</p>
        </div>
        <div className="d-flex align-items-center flex-wrap">
          <p className="text-nowrap m-0 pr-1">{localizer.client() + ":"}</p>
          <p className="m-0">{caseModel.client.name}</p>
        </div>
        <div className="d-flex align-items-center flex-wrap">
          <p className="text-nowrap m-0 pr-1">
            {localizer.counterparts() + ":"}
          </p>
          <p className="m-0">
            {caseModel.counterParts.map((part) => part.name).join(", ")}
          </p>
        </div>
        <DropdownMenu
          buttonNode={<MenuDotButton />}
          menuItems={[
            {
              component: (
                <IconTextCallbackItem
                  icon={<EmblaIcon iconName="edit" />}
                  text={localizer.editDetails()}
                  callback={() => setEditModalOpen(true)}
                />
              ),
              key: localizer.editDetails(),
            },
            {
              component: (
                <IconTextCallbackItem
                  icon={<EmblaIcon iconName="delete" />}
                  text={localizer.deleteCase()}
                  callback={() => setDeleteModalOpen(true)}
                />
              ),
              key: localizer.deleteCase(),
            },
          ]}
        />
      </div>
      <div className="px-2">
        <ClampedParagraph className="margin-top-s" lineClamp={3}>
          {caseModel.description}
        </ClampedParagraph>
      </div>
    </Card>
  );
};

export default CaseInfo;
