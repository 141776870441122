import { UserManager } from "oidc-client-ts";
import { loadOidcConfig } from "@config/oidcConfig";
import { Config } from "@config/configManager";

export class UserService {
  private userManager: UserManager;
  private idpBaseUrl: string;
  private baseUrl: string;

  constructor(config: Config, isElectronApp: boolean) {
    
    const baseUrl = isElectronApp ? config.baseUrl : window.location.origin;
    
    const oidcConfig = loadOidcConfig(config, baseUrl);
    this.idpBaseUrl = config.idpUrl;
    this.baseUrl = baseUrl;
    this.userManager = new UserManager(oidcConfig);
  }

  public async getUserToken() {
    const user = await this.userManager.getUser();
    return user && !user.expired ? user.access_token : undefined;
  }

  public async login() {
    await this.userManager.signinRedirect();
  }

  public silentlyLogin() {
    return this.userManager.signinSilent();
  }

  public waitForSignIn() {
    return this.userManager.signinRedirectCallback().then((u) => u.access_token);
  }

  public async logout() {
    await this.userManager.signoutRedirect().then((_) => {
      localStorage.clear();
    });
  }

  public async impersonate(userId: string) {
    const redirectUrl = `${this.baseUrl}/impersonate`;
    const impersonateUrl = `${this.idpBaseUrl}/account/impersonate/impersonateuser?id=${userId}&returnurl=${redirectUrl}`;
    window.location.replace(impersonateUrl);
  }

  public async stopImpersonate() {
    const stopImpersonationUrl = `${this.idpBaseUrl}/account/impersonate/stopimpersonateUser?returnUrl=${this.baseUrl}`;
    localStorage.clear();
    this.silentlyLogin();
    window.location.replace(stopImpersonationUrl);
  }

  public addSilentRenewErrorHandler(eventHandler: () => Promise<void>) {
    this.userManager.events.addSilentRenewError(eventHandler);
  }

  public removeSilentRenewErrorHandler(eventHandler: () => Promise<void>) {
    this.userManager.events.removeSilentRenewError(eventHandler);
  }
}
