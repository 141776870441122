import { usePreview } from "react-dnd-preview";
import { useMemo, useRef } from "react";
import { useExtractCompositionContext } from "@pages/extractCompositionPage/extractCompositionContextProvider";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";
import { ReorderExtractDraftSectionDocument } from "@pages/extractCompositionPage/hooks/useDragDropSectionDocumentReorder";
import { ExtractDraftSectionModel } from "@services/api/extractDraft/models/extractDraftSectionModel";
import { dndItemTypes } from "../ExtractSections/DndItemTypes";
import DragPreviewMultipleDocument from "./DragPreviewMultipleDocument/DragPreviewMultipleDocument";
import DragPreviewSingleDocument from "./DragPreviewSingleDocument/DragPreviewSingleDocument";
import DragPreviewSection from "./DragPreviewSection/DragPreviewSection";

const DragPreview = () => {
  const { selectedDocumentsReducer } = useExtractCompositionContext();
  const selectedDocuments = selectedDocumentsReducer.state.selectedDocuments;
  const preview = usePreview();

  const ref = useRef(null);
  
  const { item, style: previewStyle, itemType } = preview as { itemType: keyof typeof dndItemTypes; item: CaseDocumentModel | ReorderExtractDraftSectionDocument | ExtractDraftSectionModel; style: React.CSSProperties };
  
  const style = useMemo(() => ({
    ...previewStyle,
    zIndex: 1000,
  }), [previewStyle]);
  
  if (!preview.display) {
    return null;
  }

  const getPreview = () => {
    if (selectedDocuments.length > 0 && itemType === dndItemTypes.document) {
      return <DragPreviewMultipleDocument documentsAmount={selectedDocuments.length}/>;
    }
    if (selectedDocuments.length === 0 && itemType === dndItemTypes.document) {
      return <DragPreviewSingleDocument document={item as CaseDocumentModel | ReorderExtractDraftSectionDocument}/>;
    }
    if (itemType === dndItemTypes.section) {
      const section = item as ExtractDraftSectionModel;
      return <DragPreviewSection sectionName={section.title}/>;
    }
  };
  
  return (
    <div
      ref={ref}
      className="card gap-8 px-3 py-1"
      style={style}
    >
      {getPreview()}
    </div>
  );
};

export default DragPreview;