import { useDispatch } from "react-redux";
import Button from "@components/embla/button";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import { bulkAddStart } from "../../pages/pdfviewer/component/pdfViewerSlice";

const BulkAddButton = () => {
  const dispatch = useDispatch();
  const localization = useLocalization();

  return (
    <Button key="start-bulk-add" borderless noTheme onClick={() => dispatch(bulkAddStart())}>
      <EmblaIcon iconName="document-add" color={IconColor.DarkGray} />
      <span className="pl-2">{localization.addPagesToPresentations()}</span>
    </Button>
  );
};

export default BulkAddButton;
