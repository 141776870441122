import { BaseSyntheticEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@components/embla/card";
import FormSwitch from "@components/forms/formSwitch";
import { useLocalization } from "@components/localization/localizationProvider";
import { MentionTypeEnum } from "@components/mention/mentionModel";
import MentionRichTextArea from "@components/mention/mentionRichTextArea";
import useMentionsHandler from "@components/mention/useMentionsHandler";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { activeMarkingSelector, setUnsavedMarking } from "@pages/pdfviewer/component/pdfViewerSlice";
import { CommentMarkingModel } from "../../../models/markingModel";
import { EditCardProps } from "../shared/cardProps.model";
import MarkingCloseBtn from "../shared/markingCloseBtn";
import MarkingDeleteBtn from "../shared/markingDeleteBtn";
import MarkingMetaBlock from "../shared/markingMetaBlock";
import MarkingSaveBtn from "../shared/markingSaveBtn";
import MarkingTagsDropdown from "../shared/markingTagsDropdown";
import useMarkingCardForm from "../shared/useMarkingCardForm";
import useCommentValidation from "./useCommentValidation";

const CommentEdit = ({
  marking,
  onSave,
  onDelete,
  onClose,
  isTemplate,
}: EditCardProps<CommentMarkingModel>) => {
  const localizer = useLocalization();
  const dispatch = useDispatch();
  const validation = useCommentValidation();
  const unsaved = useSelector(activeMarkingSelector)?.unsaved;

  const { addMention, commitMentions } = useMentionsHandler(MentionTypeEnum.Comment);

  // changes to comment card are not autosaved, so don't emit to parent, but save data in store 
  const onMarkingChange = () => {
    const formOnlyData = methods.getValues();
    dispatch(setUnsavedMarking({
      ...formOnlyData,
      type: MarkingType.Comment,
      data: { ...formOnlyData.data },
    }));
  };

  const initialValues = unsaved?.type === MarkingType.Comment ? unsaved : marking;

  const { methods, submitHandler } = useMarkingCardForm({
    marking,
    onMarkingChange,
    onSave,
    defaultValues: {
      tags: initialValues.tags,
      isPrivate: initialValues.isPrivate,
      data: {
        text: initialValues.data?.text,
      },
    },
    validationSchema: isTemplate ? undefined : validation,
  });

  const handleSubmit = async (e?: (BaseSyntheticEvent | undefined)) => {
    if (marking.id) {
      await commitMentions(marking.id);
    }

    await submitHandler(e);
  };

  const { setFocus } = methods;
  useEffect(() => setFocus(isTemplate ? "tags" : "data.text"), [isTemplate, setFocus]);


  return (
    <>
      <Card.Header>
        {localizer.comment()}
        <MarkingCloseBtn close={onClose} />
      </Card.Header>
      <Card.Body>
        <MarkingMetaBlock marking={marking} />
        <form onSubmit={handleSubmit}>
          {!isTemplate &&
            <MentionRichTextArea
              methods={methods}
              id={`comment-text-${marking.id}`}
              name="data.text"
              useAutoSizeOptions={{
                minRows: 3,
                maxRows: 10,
              }}
              addMentionCallback={addMention}
            />
          }
          <MarkingTagsDropdown
            methods={methods}
            id={`comment-tags-${marking.id}`}
            name="tags"
          />
          <FormSwitch
            methods={methods}
            label={localizer.privateComment()}
            id={`comment-private-${marking.id}`}
            name="isPrivate"
          />
        </form>
      </Card.Body>
      {!isTemplate &&
        <Card.Footer>
          <MarkingDeleteBtn onDelete={onDelete} />
          {" "}
          <MarkingSaveBtn
            submit={handleSubmit}
            disabled={!unsaved || !methods.formState.isValid}
          />
        </Card.Footer>
      }
    </>
  );
};

export default CommentEdit;

