import { BaseQueryFn, createApi, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { RootState } from "@app";
import createBaseQuery from "./createBaseQuery";

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, baseQueryApi, extraOptions) => {
  const baseUrl = (baseQueryApi.getState() as RootState).config?.apiUrl;
  const rawBaseQuery = createBaseQuery(baseUrl);
  return rawBaseQuery(args, baseQueryApi, extraOptions);
};

export const apiTags = {
  user: "User" as const,
  profile: "Profile" as const,
  profilePicture: "ProfilePicture" as const,
  employee: "Employee" as const,
  localization: "Localization" as const,
  emailTemplate: "EmailTemplate" as const,
  mention: "Mention" as const,
  case: "Case" as const,
  caseDocument: "CaseDocument" as const,
  caseDocumentPage: "CaseDocumentPage" as const,
  caseDocumentBookmark: "CaseDocumentBookmark" as const,
  casePresentation: "CasePresentation" as const,
  casePresentationPdfFilesUrl: "CasePresentationPdfFilesUrl" as const,
  casePresentationPage: "CasePresentationPage" as const,
  caseParticipant: "CaseParticipant" as const,
  caseUserAccessRights: "CaseUserAccessRights" as const,
  client: "Client" as const,
  markings: "Markings" as const,
  markingTag: "MarkingTag" as const,
  caseExtractDraft: "CaseExtractDraft" as const,
  caseDocumentExport: "CaseDocumentExport" as const,
};

const baseApi = createApi({
  reducerPath: "api",
  baseQuery: dynamicBaseQuery,
  tagTypes: Object.values(apiTags),
  endpoints: () => ({}),
});

export default baseApi;
