import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useLocalization } from "@components/localization/localizationProvider";
import useValidatedForm from "@components/forms/useValidatedForm";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import {
  useExportExtractDraftMutation,
} from "@services/api/extractDraft/extractDraftApi";
import { ExportExtractDraftForm } from "@components/Export/exportExtractDraftForm";
import { ExportExtractDraftModel } from "@services/api/case/models/exportExtractDraftModel";
import { useGetCaseParticipantsQuery } from "@services/api/participant/caseParticipantApi";
import { DownloadExtractDraftExport } from "@pages/extractCompositionPage/components/export/downloadExtractDraftExport";

interface ExportExtractDraftProps {
  caseId: string;
  extractId: string;
  submitPortal: Element;
  headerPortal: Element;
}

const ExportExtractDraft = ({ caseId, extractId, submitPortal, headerPortal }: ExportExtractDraftProps) => {
  const localizer = useLocalization();
  const [exportId, setExportId] = useState<string | undefined>();
  const formFieldIdPrepend = "export-extractDraft-";

  const { data: participants, isLoading: participantsIsLoading } = useGetCaseParticipantsQuery({ caseId });
  const [ exportExtractDraft, exportExtractDraftRequest ] = useExportExtractDraftMutation();

  const initialValues: ExportExtractDraftModel = {
    markingOwners: [],
    markingTypes: [ MarkingType.Comment, MarkingType.Highlight, MarkingType.VerticalLines, MarkingType.FocusBox ],
    convertLinesToBlack: true,
  };

  const methods = useValidatedForm({
    defaultValues: initialValues,
  });

  const onSubmitExportExtractDraft = async (model: ExportExtractDraftModel) => {
    const result = await exportExtractDraft({ caseId, extractDraftId: extractId, model }).unwrap();
    if (result) {
      setExportId(result);
    }
  };

  return (
    <>
      {exportId ?
        <DownloadExtractDraftExport caseId={caseId} extractId={extractId} extractExportId={exportId} />
        :
        <>
          <div className="subtle padding-top-l">
            <p>
              {localizer.exportExtractDraftHint1()}
            </p>
            <p>
              {localizer.exportPresentationHint2()}
            </p>
          </div>
          <ExportExtractDraftForm methods={methods} fieldIdPrepend={formFieldIdPrepend} participants={participants} />
          {ReactDOM.createPortal((
            <FormSubmitButton
              state={{
                isSubmitting: exportExtractDraftRequest.isLoading,
                isSubmitSuccessful: exportExtractDraftRequest.isSuccess,
              }}
              isPrimary={true}
              onClick={methods.handleSubmit(onSubmitExportExtractDraft)}
              disabled={participantsIsLoading}
            >
              {localizer.exportVerb()}
            </FormSubmitButton>
          ), submitPortal)}

          {ReactDOM.createPortal((localizer.exportAsPdf()), headerPortal)}
        </>
      }
    </>
  );
};

export default ExportExtractDraft;

