import classNames from "classnames";
import React, { HTMLAttributes, RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { v4 as uuid } from "uuid";
import { useLocalization } from "@components/localization/localizationProvider";
import { useAppDispatch, useAppSelector } from "@hooks";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { CanvasService } from "@pages/pdfviewer/component/page/canvas/canvasService";
import MarkingIconBtn from "@pages/pdfviewer/component/page/canvas/shared/markingIconButton/markingIconBtn";
import usePageMarkings from "@pages/pdfviewer/component/page/canvas/usePageMarkings";
import VerticalLinesMarking from "@pages/pdfviewer/component/page/canvas/verticalLines/verticalLinesMarking";
import { PageDimensions } from "@pages/pdfviewer/component/pageDimensions";
import {
  activeMarkingSelector,
  activePresentationSelector,
  activeToolSelector,
  hoveredMarkingIdSelector,
  markingOwnerFiltersSelector,
  markingTypeFiltersSelector,
  pdfViewerStateSelector,
  setActiveMarking,
  setHoveredMarkingId,
} from "@pages/pdfviewer/component/pdfViewerSlice";
import { MenuDotButton } from "@components/menuButton/dropdownMenuItems/menuDotButton";
import { IconTextCallbackItem } from "@components/menuButton/dropdownMenuItems/IconTextDropdownItem";
import ExpandingDropdownMenu from "@components/expandingDropdownMenu/expandingDropdownMenu";
import useElementSize from "src/hooks/useElementSize";
import { MarkingActiveStateType } from "../../models/activeMarkingInfo";
import { PdfToolType } from "../../models/pdfTool";
import { usePdfSize } from "../../hooks/pdfSizeProvider";
import FocusBoxMarkingMarking from "./focusBox/focusBoxMarking";
import HighlightMarking from "./highlight/highlightMarking";
import styles from "./pageCanvas.module.scss";
import CanvasMarkingCard from "./shared/canvasMarkingCard";
import ResizeHandle from "./ResizeHandle/ResizeHandle";
import { usePageNumbers } from "./hooks/usePageNumbers";
import PageNumber from "./PageNumber/PageNumber";

interface MatchingRefs {
  markingRef: React.RefObject<HTMLElement>;
  cardRef: React.RefObject<HTMLElement>;
}

interface PageCanvasProps {
  pageDimensions: PageDimensions;
  pageIndex: number;
  scale?: number;
  hideCards?: boolean;
  disableEditing?: boolean;
  selectedMarkings?: string[];
  onSelectedMarkingsChange?: (markingIds: string[]) => void;
  tempMarkings?: MarkingModel[];
  isLoadingMarkingsEdit?: boolean;
}

export const PageCanvas = ({
  pageDimensions,
  pageIndex,
  scale = 1,
  hideCards = false,
  disableEditing = false,
  selectedMarkings,
  onSelectedMarkingsChange,
  tempMarkings,
  isLoadingMarkingsEdit,
}: PageCanvasProps) => {
  const { setWidth: setCanvasWidth, width: canvasWidth } = usePdfSize();
  const markingOwnerFilters = useAppSelector(markingOwnerFiltersSelector);
  const markingTypeFilters = useAppSelector(markingTypeFiltersSelector);
  const { markings: allPageMarkings, isFetching, isLoading } = usePageMarkings(pageIndex, markingOwnerFilters, markingTypeFilters);
  const localizer = useLocalization();
  const dispatch = useAppDispatch();
  
  const allPossiblePresentationMarkingIds = useMemo(() => allPageMarkings.filter((x) => x.type !== MarkingType.Comment).map((x) => x.id), [allPageMarkings]);
  const activePresentation = useAppSelector(activePresentationSelector);
  
  const [presentationMarkingIds, setPresentationMarkingIds] = useState<string[]>([]);
  
  const presentationMode = selectedMarkings !== undefined;
  const canvasService = useMemo(() => new CanvasService(pageDimensions, hideCards, presentationMode ? Math.max(pageDimensions.width / 2, 300) : canvasWidth ?? 600), [pageDimensions, hideCards, canvasWidth, presentationMode]);

  const [ containerRef, size ] = useElementSize();

  const { pageNumber } = usePageNumbers(pageIndex);

  useEffect(() => {
    if (activePresentation?.id) {
      const markingsInActivePresentation = allPageMarkings.filter((m) => m.presentationList?.some((sp) => sp.presentationId === activePresentation.id));
      setPresentationMarkingIds(markingsInActivePresentation.map((m) => m.id));
    } else if (presentationMode) {
      setPresentationMarkingIds(selectedMarkings);
    }
  }, [activePresentation, allPageMarkings, presentationMode, selectedMarkings]);

  const pdfViewerState = useAppSelector(pdfViewerStateSelector);
  const activeTool = useAppSelector(activeToolSelector);
  const activeMarkingState = useAppSelector(activeMarkingSelector);
  const hoveredMarkingId = useAppSelector(hoveredMarkingIdSelector);

  /** Merge user changes with replies form cache as newer replies information is there */
  const relevantActiveMarking = useMemo((): MarkingModel<false> | undefined => {
    if (!activeMarkingState) {
      return;
    }
    const cacheMarking = activeMarkingState.marking.page === pageIndex ? allPageMarkings.find(({ id }) => id === activeMarkingState.marking.id) : undefined;
    return !cacheMarking
      ? activeMarkingState.marking
      : {
        ...activeMarkingState.marking,
        replies: cacheMarking.replies,
      };
  }, [activeMarkingState, allPageMarkings, pageIndex]);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const cardMarkings = useMemo(
    () => (relevantActiveMarking ? (relevantActiveMarking.page === pageIndex ? [relevantActiveMarking] : []) : allPageMarkings),
    [relevantActiveMarking, allPageMarkings, pageIndex],
  );

  const shownMarkings = useMemo(() => {
    let filtered = allPageMarkings;
    if (relevantActiveMarking) {
      filtered = filtered.filter((x) => x.id !== relevantActiveMarking.id);
    }
    if (presentationMode && hideCards) {
      filtered = filtered.filter((x) => presentationMarkingIds.includes(x.id));
    }
    return filtered;
  }, [relevantActiveMarking, allPageMarkings, hideCards, presentationMode, presentationMarkingIds]);

  const clearCanvas = useCallback(() => {
    if (canvasRef.current) {
      canvasService.clearCanvas(canvasRef.current);
    }
  }, [canvasService]);

  useEffect(() => {
    if (!relevantActiveMarking) {
      clearCanvas();
    }
  }, [relevantActiveMarking, clearCanvas]);

  const markingElementRefs = useRef<{ [id: string]: MatchingRefs }>({});

  const getRefsSafe = (id: string): MatchingRefs => {
    if (!markingElementRefs.current[id]) {
      markingElementRefs.current[id] = { markingRef: React.createRef(), cardRef: React.createRef() };
    }
    return markingElementRefs.current[id];
  };

  const getMarkingRef = <T extends HTMLElement>(id: string): RefObject<T> => {
    return getRefsSafe(id).markingRef as RefObject<T>;
  };

  const getCardRef = <T extends HTMLElement>(id: string): RefObject<T> => {
    return getRefsSafe(id).cardRef as RefObject<T>;
  };

  const onCanvasPointerDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!canvasRef.current || e.button !== 0 || !("template" in activeTool) || relevantActiveMarking) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const id = uuid();
    const x = e.nativeEvent.offsetX / scale;
    const y = e.nativeEvent.offsetY / scale;
    const page = pageIndex;
    const creationDate = new Date().toJSON();
    switch (activeTool.type) {
      case MarkingType.Comment:
      case MarkingType.FocusBox: {
        dispatch(
          setActiveMarking({
            activeState: MarkingActiveStateType.Edit,
            marking: {
              ...activeTool.template,
              id,
              x,
              y,
              page,
              creationDate,
            },
          }),
        );
        break;
      }
      case MarkingType.VerticalLines: {
        const margin = 20;
        const width = pageDimensions.width / scale - margin * 2;

        dispatch(
          setActiveMarking({
            activeState: MarkingActiveStateType.Edit,
            marking: {
              ...activeTool.template,
              id,
              x: margin,
              y,
              page,
              creationDate,
              data: {
                ...activeTool.template.data,
                width,
              },
            },
          }),
        );
        break;
      }
    }
  };

  const drawConnectingLine = useCallback(
    (id: string) => {
      const markingRef = markingElementRefs.current[id];
      if (markingRef?.markingRef.current && markingRef?.cardRef.current && canvasRef?.current) {
        clearCanvas();
        canvasService.drawConnectingLine(canvasRef.current, markingRef.markingRef.current, markingRef.cardRef.current, presentationMode ? false : true);
      }
    },
    [canvasService, clearCanvas, presentationMode],
  );

  const mouseOverElement = (id: string) => {
    // Skip if its the same marking
    // Note: Use dispatch(SetHoveredMarking(undefined)) if needing to redraw connectingLine
    if (hoveredMarkingId === id)
      return;

    dispatch(setHoveredMarkingId(id));
    drawConnectingLine(id);
  };

  useEffect(() => {
    // redraw line when marking position changes (was dragged)
    if (relevantActiveMarking?.id) {
      drawConnectingLine(relevantActiveMarking.id);
    }
  }, [drawConnectingLine, relevantActiveMarking?.id, relevantActiveMarking?.x, relevantActiveMarking?.y]);

  useEffect(() => {
    // clear drawn lines when drag starts
    if (pdfViewerState.dragging) {
      clearCanvas();
    }
  }, [clearCanvas, pdfViewerState.dragging]);

  const mouseLeaveElement = () => {
    clearCanvas();
    dispatch(setHoveredMarkingId(undefined));
  };

  const getDynamicAttributes = (marking: MarkingModel): HTMLAttributes<unknown> | undefined => {
    const onMarkingButtonClicked = () => {
      clearCanvas();
      dispatch(
        setActiveMarking({
          activeState: MarkingActiveStateType.Replies,
          marking,
        }),
      );
      dispatch(setHoveredMarkingId(undefined));
    };
    return !relevantActiveMarking && !disableEditing ? { role: "button", onClick: onMarkingButtonClicked } : undefined;
  };

  const sortedMarkings = useMemo(() => [...cardMarkings].sort((a, b) => a.y - b.y), [cardMarkings]);

  const renderMarking = (marking: MarkingModel<false>) => {
    const isHovering = hoveredMarkingId === marking.id;
    const isActive = marking.id === relevantActiveMarking?.id;
    const isActiveInEdit = isActive && activeMarkingState?.activeState === MarkingActiveStateType.Edit;
    const isFaded =
      presentationMode && !isHovering && !isActiveInEdit && !presentationMarkingIds.some((markingId) => markingId === marking.id) && !marking.isTemporary;
    const isCommentFaded = marking.type === MarkingType.Comment && !isActiveInEdit && !isHovering;

    const sharedProps = {
      className: classNames(styles.overlayElement, styles.transitions, {
        "shadow-2": isHovering || isActive,
        [styles.notActive]: relevantActiveMarking && !isActive,
        [styles.faded]: isFaded,
        [styles.commentFaded]: isCommentFaded,
      }),

      onMouseOver: () => mouseOverElement(marking.id),
      onMouseLeave: () => mouseLeaveElement(),
      key: marking.id,
      active: isActive,
      scale,
      ...getDynamicAttributes(marking),
    };

    switch (marking.type) {
      case MarkingType.VerticalLines:
        return <VerticalLinesMarking ref={getMarkingRef(marking.id)} marking={marking} faded={isFaded} {...sharedProps} />;
      case MarkingType.FocusBox:
        return <FocusBoxMarkingMarking ref={getMarkingRef(marking.id)} marking={marking} faded={isFaded} {...sharedProps} />;
      case MarkingType.Highlight:
        return (
          <HighlightMarking
            ref={getMarkingRef(marking.id)}
            marking={marking}
            faded={isFaded}
            {...sharedProps}
            className={classNames(styles.highlight, sharedProps.className)}
          />
        );
      default:
        return (
          <MarkingIconBtn
            ref={getMarkingRef(marking.id)}
            marking={marking}
            isPresentationMode={presentationMode}
            {...sharedProps}
            className={classNames(styles.overlayBtn, sharedProps.className)}
          />
        );
    }
  };

  const renderMarkingCards = () => {
    return sortedMarkings
      .filter((t) => !presentationMode || t.type !== MarkingType.Comment)
      .map((marking) => {
        const isAddedToPresentation = presentationMarkingIds.some((markingId) => markingId === marking.id);
        const isHovering = hoveredMarkingId === marking.id;

        const isActive = marking.id === relevantActiveMarking?.id;
        const isActiveInEdit = isActive && activeMarkingState?.activeState === MarkingActiveStateType.Edit;
        const showAddedToPresentation = isAddedToPresentation && !isActiveInEdit;

        return (
          <div key={marking.id} style={isActive ? { position: "sticky", top: marking.y * scale - 70 } : undefined} className={styles.markingCardContainer}>
            <CanvasMarkingCard
              key={`${marking.id}:${isActive}`} // use isActive as part of key to reset component state
              ref={getCardRef(marking.id)}
              marking={marking}
              presentationMode={presentationMode}
              editMarkingClicked={() => editMarkingClicked(marking)}
              toggleMarkingClicked={(id, value) => markingSwitchChanged(id, value)}
              toggleMarkingState={isAddedToPresentation}
              onMouseOver={() => mouseOverElement(marking.id)}
              onMouseLeave={() => mouseLeaveElement()}
              activeInfo={isActive ? activeMarkingState : undefined}
              className={classNames("marking-card", (isHovering || isActive) && "shadow-2", showAddedToPresentation && styles.addedToPresentation)}
              disableCheckbox={isFetching || isLoading || isLoadingMarkingsEdit}
              {...getDynamicAttributes(marking)}
            />
          </div>
        );
      });
  };

  const markingSwitchChanged = (markingId: string, checked: boolean) => {
    const newMarkingIds = [...presentationMarkingIds];

    if (checked) {
      newMarkingIds.push(markingId);
      setPresentationMarkingIds(newMarkingIds);
    } else {
      const index = newMarkingIds.indexOf(markingId);
      newMarkingIds.splice(index, 1);
      setPresentationMarkingIds(newMarkingIds);
    }

    onSelectedMarkingsChange?.(newMarkingIds);
  };

  const editMarkingClicked = (marking: MarkingModel) => {
    clearCanvas();
    dispatch(
      setActiveMarking({
        activeState: MarkingActiveStateType.Edit,
        marking,
      }),
    );
    dispatch(setHoveredMarkingId(undefined));
  };

  const canvasNotActive = activeTool.type === MarkingType.Highlight || activeTool.type === PdfToolType.SelectText;

  const selectAllMarkings = (toggled: boolean) => {
    const selected = toggled ? allPossiblePresentationMarkingIds : [];
    setPresentationMarkingIds(selected);
    onSelectedMarkingsChange?.(selected);
  };

  return (
    <>
      <div className={classNames(canvasNotActive && styles.notActive)}>
        <canvas
          ref={canvasRef}
          onPointerDown={onCanvasPointerDown}
          style={{ left: presentationMode ? canvasService.canvasLeftOffset(canvasRef.current) : 0 }}
          width={canvasService.canvasWidth}
          height={pageDimensions.height}
          className={classNames(styles.canvas)}
        />

        <DndProvider backend={HTML5Backend}>
          {shownMarkings.map((marking) => renderMarking(marking))}

          {tempMarkings?.map((marking) => renderMarking(marking))}

          {pageNumber &&  <PageNumber scale={scale} {...pageNumber}/>}

          {relevantActiveMarking?.page === pageIndex && renderMarking(relevantActiveMarking)}
        </DndProvider>
      </div>
      {!hideCards && (
        <div className={classNames("d-flex flex-colum", styles.overlayElement)} style={canvasService.canvasLaneStyle(!presentationMode)}>
          <div className={classNames(styles.cardsContainer, presentationMode && styles.presentationEdit)} style={{ maxWidth: canvasWidth }}>
            {presentationMode && allPossiblePresentationMarkingIds.length > 0 && (
              <div className={classNames(styles.transparentCardHeader)}>
                <div className={classNames("d-flex justify-content-between")}>
                  <h3>{localizer.markings()}</h3>
                  <div>
                    <ExpandingDropdownMenu buttonNode={<MenuDotButton />} menuDropdownPlacement={"bottom"} triggeredBy={"leftclick"}>
                      <ExpandingDropdownMenu.ButtonItem key={localizer.selectAll()} onClick={() => selectAllMarkings(true)}>
                        <IconTextCallbackItem text={localizer.selectAll()} />
                      </ExpandingDropdownMenu.ButtonItem>
                      <ExpandingDropdownMenu.ButtonItem key={localizer.selectNone()} onClick={() => selectAllMarkings(false)}>
                        <IconTextCallbackItem text={localizer.selectNone()} />
                      </ExpandingDropdownMenu.ButtonItem>
                    </ExpandingDropdownMenu>
                  </div>
                </div>
              </div>
            )}
            <div ref={containerRef}>{renderMarkingCards()}</div>
          </div>
          {!presentationMode && allPageMarkings.length > 0 && (
            <ResizeHandle
              style={{ maxHeight: size.height }}
              onResize={(deltaX) => {
                if (setCanvasWidth) {
                  setCanvasWidth(deltaX * 2);
                }
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
