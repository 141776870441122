import React from "react";
import { useDragLayer, XYCoord } from "react-dnd";
import Card from "@components/embla/card";

const layerStyles: React.CSSProperties = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
};

const getItemStyles = (initialOffset: XYCoord | null, currentOffset: XYCoord | null, item: any, clientOffset:XYCoord | null) => {
  if (!initialOffset || !currentOffset || !clientOffset) {
    return {
      display: "none",
    };
  }

  const { x, y } = clientOffset;
  const transform = `translate(${x - item.width / 2}px, ${y}px)`;

  return {
    transform,
  };
};

type DragLayerProps = {
  dndType: string;
};

const DragLayer = ({ dndType }: DragLayerProps) => {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
    clientOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));

  const renderItem = () => {
    switch (itemType) {
      case dndType:
        return  <Card>
          <Card.Body>
            <p className="ellipsisContainer">{item.note}</p>
          </Card.Body>
        </Card>;
      default:
        return null;
    }
  };
  

  if (!isDragging) {
    return null;
  }

  return (
    <div style={{ ...layerStyles }}>
      <div style={{ ...getItemStyles(initialOffset, currentOffset, item, clientOffset), width: item.width }}>
        {renderItem()}
      </div>
    </div>
  );
};

export default DragLayer;