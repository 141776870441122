import PagePlaceholder from "@components/pagePlaceholder/pagePlaceholder";
import { DocumentPageOverlayIconTypeEnum } from "../models/documentPageOverlayIconType";
import Thumbnail from "./thumbnail";

type ThumbnailContainerProps = {
  srcUrl?: string;
  fileName?: string;
  title?: string;
  additionalClasses?: string;
  overlayIcons?: DocumentPageOverlayIconTypeEnum[];
  size?: { width: number, heigth: number }
  active?: boolean;
};

const ThumbnailContainer = ({ srcUrl, fileName, title, overlayIcons, size, additionalClasses, active }: ThumbnailContainerProps) => {

  return (
    srcUrl ?
      <Thumbnail
        srcUrl={srcUrl}
        fileName={fileName}
        title={title}
        overlayIcons={overlayIcons}
        active={active}
        size={size}
        additionalClasses={additionalClasses}
      />
      :
      <PagePlaceholder emptyPlaceholder size={"small"} margins={3} active={active} />
  );
};

export default ThumbnailContainer;
