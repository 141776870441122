import { MouseEventHandler, ReactNode } from "react";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { Dimensions } from "@components/resizable/resizable";
import { Spinner } from "@components/spinner/spinner";
import SinglePagePdfView from "@pages/pdfviewer/component/singlePagePdfView";
import PageInfoHeader from "@pages/present/pageInfoHeader";
import { MinimalPresentationPageModel } from "@services/api/case/models/casePresentationPageModel";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import styles from "./presentPage.module.scss";

interface PresentPageProps {
  pdfUrl?: string;
  pageIndex: number;
  pageIndexDisplay?: number
  pageData?: MinimalPresentationPageModel;
  zoomArea?: Dimensions;
  isLoading: boolean;
  pageOverlay?: (scale: number) => ReactNode;
  onLoadSuccess?: (pdf: DocumentCallback) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
  tempMarkings?: MarkingModel[];
}

const PresentPage = ({
  pdfUrl,
  pageIndex,
  pageIndexDisplay,
  pageData,
  zoomArea,
  isLoading,
  pageOverlay,
  onLoadSuccess,
  onClick,
  tempMarkings,
}: PresentPageProps) => {

  const pageIsVertical = !pageData?.orientation || pageData.orientation === 180;

  return (
    <div
      className={styles.container}
      onClick={onClick}
    >
      {isLoading
        ? <Spinner className="h-100vh" />
        : pdfUrl &&
          <>
            {/* {<PageInfoHeader
              className={styles.header}
              pageIndex={pageIndexDisplay ? pageIndexDisplay : pageIndex}
              pageData={pageData}
              overlay /> } */}
            <SinglePagePdfView
              pdfUrl={pdfUrl}
              pdfPageIndex={pageIndex}
              zoomArea={zoomArea}
              pageOverlay={pageOverlay}
              onDocumentLoadSuccess={onLoadSuccess}
              hideCards
              orientation={pageData?.orientation}
              pageMargins={pageIsVertical ? 10 : 50}
              disableEditing
              hidePresentationBtn
              presentedMarkings={pageData?.markingIds}
              tempMarkings={tempMarkings} />
          </>}
    </div>
  );
};

export default PresentPage;
