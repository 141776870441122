import { useDrag, useDrop } from "react-dnd";
import { useState, useRef } from "react";
import { ExtractDraftSectionModel } from "@services/api/extractDraft/models/extractDraftSectionModel";
import { useReorderExtractDraftSectionMutation } from "@services/api/extractDraft/extractDraftApi";
import { ExtractDraftModel } from "@services/api/extractDraft/models/extractDraftModel";
import { dndItemTypes } from "../components/ExtractSections/DndItemTypes";

export const useDragDropSectionReorder = (sectionData: ExtractDraftSectionModel, extractDraft:ExtractDraftModel) => {
  const [position, setPosition] = useState<"above" | "below" | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [ reorderSection ] = useReorderExtractDraftSectionMutation();

  const [{ isDragging }, drag] = useDrag({
    type: dndItemTypes.section,
    item: sectionData,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropSection] = useDrop({
    accept: dndItemTypes.section,
    drop: (droppedSection: ExtractDraftSectionModel) => {
      if (droppedSection.sortOrder === sectionData.sortOrder ||
        (droppedSection.sortOrder === sectionData.sortOrder + 1 && position === "below")) {
        return;
      }
      const model = {
        newSortOrder: sectionData.sortOrder,
      };

      if (droppedSection.sortOrder < sectionData.sortOrder && position === "above") {
        model.newSortOrder -= 1;
      }

      reorderSection({
        caseId: extractDraft.caseId,
        extractDraftId: extractDraft.id,
        sectionId: droppedSection.id,
        model,
      });
    },
    hover: (item, monitor) => {
      if (!divRef.current) {
        return;
      }

      const hoverBoundingRect = divRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (clientOffset) {
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    
        if (hoverClientY < hoverMiddleY) {
          setPosition("above");
        } else {
          setPosition("below");
        }
      }
    },
    collect: (monitor) => {
      if (!monitor.isOver()) {
        setPosition(null);
      }
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  drag(divRef);
  dropSection(divRef);

  return { divRef, position, isDragging };
};