import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema, testValueArray, testValueShape } from "@components/validation/createValidationSchema";
import { CaseClientModel } from "@services/api/case/models/caseClientModel";
import { CaseEditModel } from "@services/api/case/models/caseModel";

export const editCaseValidation = (localizer: Localizer) => createValidationSchema<CaseEditModel>({
  id: yup.string(),
  title: yup.string().required(localizer.titleRequired()).max(150, localizer.maxLength150()),
  client: testValueShape<CaseClientModel>({
    id: yup.string(),
    name: yup.string().max(150, localizer.maxLength150()),
  }).required(localizer.clientRequired()).nullable(),
  description: yup.string(),
  courtCaseNumber: yup.string().max(150, localizer.maxLength150()),
  internalCaseNumber: yup.string().max(150, localizer.maxLength150()),
  counterparts: testValueArray(yup.string().max(150, localizer.maxLength150())),
  status: yup.number(),
});
