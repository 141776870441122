import classNames from "classnames";
import { HTMLAttributes, ReactNode } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { useAuth } from "@components/auth/authProvider";
import { useLocalization } from "@components/localization/localizationProvider";
import { ReactComponent as Cursor } from "@content/icons/cursor.svg";
import { ReactComponent as TextCursor } from "@content/icons/text-cursor.svg";
import { useAppDispatch, useAppSelector } from "@hooks";
import { Key } from "@infrastructure/Key";
import useShortcut from "src/hooks/useShortcut";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { joyrideConstants } from "@components/joyrideOverlay/joyrideConstants";
import ExpandingDropdownMenu from "@components/expandingDropdownMenu/expandingDropdownMenu";
import { MarkingActiveStateType } from "./models/activeMarkingInfo";
import { MarkingType } from "./models/markingType";
import { PdfToolType, UndoRedoToolType } from "./models/pdfTool";
import MarkingCard from "./page/canvas/shared/markingCard";
import { createCommentMarking, createFocusBoxMarking, createHighlightMarking, createVerticalLinesMarking } from "./page/canvas/utils/createMarking";
import { activeMarkingSelector, activeToolSelector, eventLogStateSelector, removeActiveMarking, setActiveTool, updateTemplate } from "./pdfViewerSlice";
import MarkingIcon from "./shared/markingIcon";
import styles from "./toolbox.module.scss";
import ToolboxCard from "./toolboxCard";
import useMarkingMutation from "./hooks/useMarkingMutation";

const nonMarkingToolIcon = {
  [PdfToolType.SelectMarking]: <Cursor />,
  [PdfToolType.SelectText]: <TextCursor />,
};

const Toolbox = ({ className, ...divProps }: HTMLAttributes<HTMLDivElement>) => {
  const activeTool = useAppSelector(activeToolSelector);
  const activeMarking = useAppSelector(activeMarkingSelector);
  const eventlog = useAppSelector(eventLogStateSelector);
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const { postEvent } = useMarkingMutation();
  
  const resetBaseTool = () => dispatch(setActiveTool({ type: PdfToolType.SelectMarking }));

  const toolClickHandler = async (type: PdfToolType | MarkingType | UndoRedoToolType) => {
    if (type === activeTool?.type) {
      if (type !== PdfToolType.SelectMarking) {
        resetBaseTool();
      }
      return;
    }
    switch (type) {
      case UndoRedoToolType.Undo: {
        if (eventlog.eventList.length > 0 && eventlog.currentEventIndex >= 0) {
          const eventMemo = eventlog.eventList[eventlog.currentEventIndex];
    
          const undoEvent = { ...eventMemo.undoEvent, id: uuid(), eventCreationDate: new Date().toJSON() };
          postEvent(undoEvent, UndoRedoToolType.Undo );
          dispatch(removeActiveMarking());
        }
        return;
      }
      case UndoRedoToolType.Redo: {
        if (eventlog.eventList.length > 0 && eventlog.currentEventIndex !== eventlog.eventList.length - 1) {
          const eventMemo = eventlog.eventList[eventlog.currentEventIndex + 1];

          const redoEvent = { ...eventMemo.originalEvent, id: uuid(), eventCreationDate: new Date().toJSON() };
          await postEvent(redoEvent, UndoRedoToolType.Redo );

          dispatch(removeActiveMarking());
        }
        return;
      }
      case MarkingType.Comment: {
        dispatch(setActiveTool({ type, template: createCommentMarking({ user }) }));
        return;
      }
      case MarkingType.Highlight: {
        dispatch(setActiveTool({ type, template: createHighlightMarking({ user }) }));
        return;
      }
      case MarkingType.VerticalLines: {
        dispatch(setActiveTool({ type, template: createVerticalLinesMarking({ user }) }));
        return;
      }
      case MarkingType.FocusBox: {
        dispatch(setActiveTool({ type, template: createFocusBoxMarking({ user }) }));
        return;
      }
      default:
        dispatch(setActiveTool({ type }));
        return;
    }
  };

  useShortcut({ key: Key.Escape, allowOnInputs: true, callback: () => toolClickHandler(PdfToolType.SelectMarking) });
  useShortcut({ key: "v", callback: () => toolClickHandler(PdfToolType.SelectMarking) });
  useShortcut({ key: "t", callback: () => toolClickHandler(PdfToolType.SelectText) });
  useShortcut({ key: "k", callback: () => toolClickHandler(MarkingType.Comment) });
  useShortcut({ key: "h", callback: () => toolClickHandler(MarkingType.Highlight) });
  useShortcut({ key: "i", callback: () => toolClickHandler(MarkingType.VerticalLines) });
  useShortcut({ key: "f", callback: () => toolClickHandler(MarkingType.FocusBox) });

  useShortcut({ key: "z", modifier: true, callback: () => toolClickHandler(UndoRedoToolType.Undo) });
  useShortcut({ key: "y", modifier: true, callback: () => toolClickHandler(UndoRedoToolType.Redo) });

  return (
    <div className={classNames(className)} {...divProps}>
      {!activeMarking && "template" in activeTool && activeTool.type !== MarkingType.Comment && (
        <MarkingCard
          className={classNames(styles.templateCard, "margin-right-s", "mb-0")}
          marking={activeTool.template}
          onClose={() => dispatch(resetBaseTool())}
          onMarkingChange={(data) => dispatch(updateTemplate(data))}
          isTemplate
          activeState={MarkingActiveStateType.Edit}
        />
      )}
      <div className="d-flex gap-s">
        <div className={classNames(joyrideConstants.documentViewerToolboxSelector, "d-flex gap-s")}>
          <ToolboxBtn key={PdfToolType.SelectMarking} type={PdfToolType.SelectMarking} toolClicked={toolClickHandler}>
            {nonMarkingToolIcon[PdfToolType.SelectMarking]}
          </ToolboxBtn>
          <ExpandingDropdownMenu
            menuDropdownPlacement="bottom"
            buttonNode={
              <ToolboxCard.Btn active={activeTool.type === MarkingType.Highlight || activeTool.type === PdfToolType.SelectText}>
                {activeTool.type === MarkingType.Highlight ? <MarkingIcon markingType={MarkingType.Highlight} /> : nonMarkingToolIcon[PdfToolType.SelectText]}
                <EmblaIcon iconName="arrow-down" />
              </ToolboxCard.Btn>
            }
          >
            <div className="d-flex flex-column">
              <ExpandingDropdownMenu.ButtonItem>
                <ToolboxDropdownOption toolClicked={toolClickHandler} type={PdfToolType.SelectText}>
                  {nonMarkingToolIcon[PdfToolType.SelectText]}
                </ToolboxDropdownOption>
              </ExpandingDropdownMenu.ButtonItem>
              <ExpandingDropdownMenu.ButtonItem>
                <ToolboxDropdownOption toolClicked={toolClickHandler} type={MarkingType.Highlight}>
                  <ToolboxCard.Btn>
                    <MarkingIcon markingType={MarkingType.Highlight}/>
                  </ToolboxCard.Btn>
                </ToolboxDropdownOption>
              </ExpandingDropdownMenu.ButtonItem>
            </div>
          </ExpandingDropdownMenu>
        </div>
        <div className={styles.divider}></div>
        <div className={classNames(joyrideConstants.documentViewerToolboxMarking, "d-flex gap-s")}>
          <ToolboxBtn type={MarkingType.Comment} toolClicked={toolClickHandler}>
            <MarkingIcon key={MarkingType.Comment} markingType={MarkingType.Comment} />
          </ToolboxBtn>
          <ToolboxBtn key={MarkingType.VerticalLines} type={MarkingType.VerticalLines} toolClicked={toolClickHandler}>
            <MarkingIcon key={MarkingType.VerticalLines} markingType={MarkingType.VerticalLines} />
          </ToolboxBtn>
          <ExpandingDropdownMenu
            menuDropdownPlacement="bottom"
            buttonNode={
              <ToolboxCard.Btn active={activeTool.type === MarkingType.FocusBox}>
                <MarkingIcon markingType={MarkingType.FocusBox} />
                <EmblaIcon iconName="arrow-down" />
              </ToolboxCard.Btn>
            }
          >
            <div className="d-flex flex-column">
              <ExpandingDropdownMenu.ButtonItem>
                <ToolboxDropdownOption toolClicked={toolClickHandler} type={MarkingType.FocusBox}>
                  <ToolboxCard.Btn>
                    <MarkingIcon markingType={MarkingType.FocusBox} />
                  </ToolboxCard.Btn>
                </ToolboxDropdownOption>
              </ExpandingDropdownMenu.ButtonItem>
            </div>
          </ExpandingDropdownMenu>
        </div>
        <div className={styles.divider}></div>
        <ToolboxBtn key={"undo-tool"} type={UndoRedoToolType.Undo} toolClicked={toolClickHandler}>
          <EmblaIcon iconName="before" />
        </ToolboxBtn>
        <ToolboxBtn key={"redo-tool"} type={UndoRedoToolType.Redo} toolClicked={toolClickHandler}>
          <EmblaIcon iconName="next" />
        </ToolboxBtn>
        {/* TODO: Fix flip */}
        {/* <div className={styles.divider}></div> */}
        {/* <FlipControlButton /> */}
      </div>
    </div>
  );
};

const ToolboxBtn = (props: { type: MarkingType | PdfToolType | UndoRedoToolType, toolClicked: (type: PdfToolType | MarkingType | UndoRedoToolType) => void, children: ReactNode }) => {
  const localizer = useLocalization();
  const activeTool = useSelector(activeToolSelector);

  const titles = {
    [PdfToolType.SelectMarking]: `${localizer.select()} (V)`,
    [PdfToolType.SelectText]: `${localizer.textSelect()} (T)`,
    [MarkingType.Comment]: `${localizer.comment()} (K)`,
    [MarkingType.Highlight]: `${localizer.highlightTitle()} (H)`,
    [MarkingType.VerticalLines]: `${localizer.verticalLinesTitle()} (I)`,
    [MarkingType.FocusBox]: localizer.focusBoxTitle() + " (F)",
    [UndoRedoToolType.Undo]: localizer.undo() + " (CTRL + Z)",
    [UndoRedoToolType.Redo]: localizer.redo() + " (CTRL + Y)",
  };
  
  return (
    <ToolboxCard.Btn
      tooltip={titles[props.type]}
      active={activeTool?.type === props.type}
      onClick={() => props.toolClicked(props.type)}
    >
      {props.children}
    </ToolboxCard.Btn>
  );
};

type ToolboxDropdownOptionProps = {
  children: ReactNode;
  toolClicked: (type: PdfToolType | MarkingType | UndoRedoToolType) => void;
  type: MarkingType | PdfToolType | UndoRedoToolType
};

const ToolboxDropdownOption = ({ children, toolClicked, type } : ToolboxDropdownOptionProps) => {
  const localizer = useLocalization();

  const titles = {
    [PdfToolType.SelectMarking]: localizer.select(),
    [PdfToolType.SelectText]: localizer.textSelect(),
    [MarkingType.Comment]: localizer.comment(),
    [MarkingType.Highlight]: localizer.highlightTitle(),
    [MarkingType.VerticalLines]: localizer.verticalLinesTitle(),
    [MarkingType.FocusBox]: localizer.focusBoxTitle(),
    [UndoRedoToolType.Undo]: localizer.undo(),
    [UndoRedoToolType.Redo]: localizer.redo(),
  };

  const shortcuts = {
    [PdfToolType.SelectMarking]: "(V)",
    [PdfToolType.SelectText]: "(T)",
    [MarkingType.Comment]: "(K)",
    [MarkingType.Highlight]: "(H)",
    [MarkingType.VerticalLines]: "(I)",
    [MarkingType.FocusBox]: "(F)",
    [UndoRedoToolType.Undo]: "(CTRL + Z)",
    [UndoRedoToolType.Redo]: "(CTRL + Y)",
  };


  return (
    <div
      className={classNames(styles.option, "d-flex justify-content-between w-100 align-items-center")}
      onClick={() => toolClicked(type)}
    >
      <div className="d-flex align-items-center">
        <div className={styles.optionIcon}>
          {children}
        </div>
        <h4 className="pl-2">
          {titles[type]}
        </h4>
      </div>
      <h4>
        {shortcuts[type]}
      </h4>
    </div>
  );
};

export default Toolbox;