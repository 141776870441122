import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import "./roundBadge.scss";

export enum BadgeSize {
  Small,
  ExtraSmall,
  ExtraExtraSmall,
}

type RoundBadgeProps = {
  text?: string;
  size?: BadgeSize;
  smallText?: boolean;
  colorOverride?: "secondary" | "secondary-light";
};

export const RoundBadge: React.FC<PropsWithChildren<RoundBadgeProps>> = (props) => {

  return (
    <div className={classNames(
      "round-badge",
      "color-primary",
      "display-3",
      {
        "small": props.smallText,
        "size-s": props.size === BadgeSize.Small || props.smallText,
        "size-xs": props.size === BadgeSize.ExtraSmall && !props.smallText,
        "size-xxs": props.size === BadgeSize.ExtraExtraSmall && !props.smallText,
      }, props.colorOverride)}
    >
      {props.children ?? props.text}
    </div>
  );
};
