import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Document } from "react-pdf";
import { FixedSizeGrid, GridOnItemsRenderedProps, ListOnItemsRenderedProps, VariableSizeList } from "react-window";
import { useParams } from "react-router-dom";
import { useAppSelector } from "@hooks";
import { useVisiblePages } from "@pages/pdfviewer/component/hooks/useVisiblePages";
import { PageGridDraggable, PageListChildDataDnd, PageListDraggable } from "@pages/pdfviewer/component/page/pageRenderer";
import { pdfViewerStateSelector } from "@pages/pdfviewer/component/pdfViewerSlice";
import useElementSize from "src/hooks/useElementSize";
import { useGetDocumentPagesQuery } from "@services/api/document/caseDocumentApi";
import { PageDimensions, PageDimensionsArray } from "../pageDimensions";
import styles from "./pdfSidebar.module.scss";
import { pdfSidebarStateSelector } from "./pdfSidebarSlice";

const sidebarGridColumnCount = 5;
const thumbnailSize = {
  width: 85,
  height: 110,
  margin: 12,
  numberHeight: 22,
};
const gridSizes = {
  columnWidth: thumbnailSize.height + thumbnailSize.margin,
  rowHeight: thumbnailSize.height + thumbnailSize.margin + thumbnailSize.numberHeight,
};

export const PdfSidebarPages = (props: { fileUrl: string, pdfDimensions: PageDimensionsArray, className?: string, documentId?: string }) => {
  const [items, setItems] = useState<ListOnItemsRenderedProps | GridOnItemsRenderedProps>();
  const [pagesVisible] = useVisiblePages(items, sidebarGridColumnCount);
  const pdfViewerState = useAppSelector(pdfViewerStateSelector);
  const [scrollContainerRef, scrollContainerSize] = useElementSize();
  const pdfSidebarState = useAppSelector(pdfSidebarStateSelector);
  const { caseId } = useParams();

  const [pageView, setPageView] = useState<VariableSizeList | FixedSizeGrid | null>(null);

  const { data: pagesInfo } = useGetDocumentPagesQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { caseId: caseId!, documentId: props.documentId! },
    { skip: !caseId || !props.documentId },
  );

  useEffect(() => {
    const pageIndex = pdfViewerState.mostVisiblePageIndex;
    if (pageView instanceof VariableSizeList) {
      pageView?.scrollToItem(pageIndex);
    } else {
      pageView?.scrollToItem({
        rowIndex: Math.trunc(pageIndex / sidebarGridColumnCount),
        columnIndex: pageIndex % sidebarGridColumnCount,
      });
    }
  }, [pageView, pdfViewerState.mostVisiblePageIndex]);
  
  const scaledDimensions = useMemo(
    () => props.pdfDimensions.map(({ width, height, rotation = 0 }): PageDimensions => {
      const flipWidthAndHeight = (rotation - 90) % 180 === 0;

      let scaleToFit = flipWidthAndHeight ?  thumbnailSize.height / height : thumbnailSize.width / width;
      if (!pdfSidebarState.sidebarCollapsed) {
        // fit to height in grid too
        scaleToFit = Math.min(scaleToFit, thumbnailSize.height / height);
      }

      const scaledWidth = width * scaleToFit;
      const scaledHeight = height * scaleToFit;

      return {
        width: flipWidthAndHeight ? scaledHeight : scaledWidth,
        height: flipWidthAndHeight ? scaledWidth / 2 : scaledHeight,
      };
    }),
    [props.pdfDimensions, pdfSidebarState.sidebarCollapsed],
  );

  useEffect(() => {
    if (pageView instanceof VariableSizeList) {
      pageView.resetAfterIndex(0);
    }
  }, [scaledDimensions, pageView]);


  const calculateRowCount = (): number => {
    return Math.ceil(pdfViewerState.totalPages / sidebarGridColumnCount);
  };

  const getListItemHeight = (index: number): number => {
    const lastPageMargin = index === pdfViewerState.totalPages - 1 ? thumbnailSize.margin : 0;
    return (scaledDimensions?.[index].height ?? 0) + thumbnailSize.margin + thumbnailSize.numberHeight + lastPageMargin;
  };

  const itemData: PageListChildDataDnd = {
    getPageClassName: (index) => classNames("rounded overflow-hidden", index === pdfViewerState.mostVisiblePageIndex && styles.active),
    isThumbnail: true,
    pdfDimensions: scaledDimensions,
    visiblePages: pagesVisible,
    pageMargin: thumbnailSize.margin,
    documentId: props.documentId || "",
    pageOrientations: pagesInfo?.map((page) => page.pageOrientation) || [],
  };

  return (
    <div
      className={classNames(styles.sidebarScroll, props.className)}
      ref={scrollContainerRef}
    >
      {!pdfSidebarState.sidebarCollapsed
        ? (
          <Document
            className={classNames("card-body", styles.document)}
            file={props.fileUrl}
            loading={<div />}>
            <FixedSizeGrid
              ref={setPageView}
              columnCount={sidebarGridColumnCount}
              rowCount={calculateRowCount()}
              columnWidth={gridSizes.columnWidth}
              rowHeight={gridSizes.rowHeight}
              className={classNames(styles.documentFixedListScroll, "scrollbar")}
              height={scrollContainerSize.height}
              width={scrollContainerSize.width}
              onItemsRendered={setItems}
              itemData={{
                ...itemData,
                gridColumnCount: sidebarGridColumnCount,
              }}
              useIsScrolling
            >
              {PageGridDraggable}
            </FixedSizeGrid>
          </Document>
        ) : (
          <Document
            className={classNames("card-body", styles.document)}
            file={props.fileUrl}
            loading={<div />}>
            <VariableSizeList
              ref={setPageView}
              className={classNames(styles.documentFixedListScroll, "scrollbar")}
              height={scrollContainerSize.height}
              itemCount={pdfViewerState.totalPages}
              itemSize={getListItemHeight}
              estimatedItemSize={getListItemHeight(0)}
              width="100%"
              onItemsRendered={setItems}
              itemData={itemData}
              useIsScrolling
            >
              {PageListDraggable}
            </VariableSizeList>
          </Document>
        )
      }
    </div>
  );
};
