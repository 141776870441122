import { PageDimensions } from "../../pageDimensions";

export const pdfCommentLaneSpaceWidth = 20;
export const pdfPageMargin = 20;

export class CanvasService {
  constructor(
    private pageDimensions: PageDimensions,
    private hideCards: boolean,
    private pdfCommentLaneWidth: number,
  ) { }

  public get canvasWidth() {
    if (this.hideCards) {
      return this.pageDimensions.width;
    }
    return this.pageDimensions.width + this.pdfCommentLaneWidth + pdfCommentLaneSpaceWidth;
  }

  public canvasLeftOffset(element: HTMLElement | null) {
    if (element) {
      return this.pageDimensions.width - this.canvasWidth;
    }
    return 0;
  }

  public canvasElementX = (
    canvasElement: HTMLCanvasElement,
    element: HTMLElement,
    rightSide = true) => {
    const elementX = element.getBoundingClientRect().left - canvasElement.getBoundingClientRect().left;
    return rightSide ? elementX + element.clientWidth : elementX;
  };

  public canvasElementY = (canvasElement: HTMLCanvasElement, element: HTMLElement) => {
    return element.getBoundingClientRect().top - canvasElement.getBoundingClientRect().top + (element.clientHeight / 2);
  };

  public canvasRightMenuX = () => this.pageDimensions.width + pdfCommentLaneSpaceWidth;

  public canvasLaneStyle = (markingsRight: boolean) => {
    if (markingsRight) {
      return {
        height: this.pageDimensions.height,
        width: this.pdfCommentLaneWidth,
        left: this.canvasRightMenuX(),
      };
    } else {
      return {
        height: this.pageDimensions.height,
        width: this.pdfCommentLaneWidth,
        right: this.canvasRightMenuX(),
      };
    }
  };

  public clearCanvas = (canvasElement: HTMLCanvasElement) => {
    const canvasContext = canvasElement.getContext("2d");
    canvasContext?.clearRect(0, 0, canvasElement.clientWidth, canvasElement.clientHeight);
  };

  public drawConnectingLine = (
    canvasElement: HTMLCanvasElement,
    fromElement: HTMLElement,
    toElement: HTMLElement,
    markingsRight = true,
  ) => {
    const startX = this.canvasElementX(canvasElement, fromElement, markingsRight ? true : false);
    const startY = this.canvasElementY(canvasElement, fromElement);
    const endX = this.canvasElementX(canvasElement, toElement, markingsRight ? false : true);
    const endY = this.canvasElementY(canvasElement, toElement);
    this.drawLine(canvasElement, startX, startY, endX, endY);
  };

  public drawLine = (
    canvasElement: HTMLCanvasElement,
    xFrom: number,
    yFrom: number,
    xTo: number,
    yTo: number) => {
    const canvasContext = canvasElement.getContext("2d");
    if (canvasContext) {
      canvasContext.beginPath();
      canvasContext.moveTo(xFrom, yFrom);
      canvasContext.lineTo(xTo, yTo);
      canvasContext.setLineDash([10, 5]);
      canvasContext.strokeStyle = "#989ba9"; // $color-gray-70
      canvasContext.stroke();
    }
  };
}


