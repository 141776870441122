import { useEffect } from "react";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { useAppDispatch } from "@hooks";
import { PdfType, initPdfViewer, setTotalPages } from "@pages/pdfviewer/component/pdfViewerSlice";
import { useGetPresentationPageFilesQuery } from "@services/api/presentation/presentationApi";

const usePresentationDocument = (presentationId: string, pollingRate?: number | undefined) => {
  const dispatch = useAppDispatch();

  const { data: downloadUrlModel, isLoading } = useGetPresentationPageFilesQuery(presentationId, { pollingInterval: pollingRate });
  useEffect(() => {
    dispatch(initPdfViewer({ pdfTypeId: presentationId, pdfType: PdfType.Presentation }));
  }, [dispatch, presentationId]);

  const handleLoadSuccess = (pdf: DocumentCallback) => {
    dispatch(setTotalPages(pdf.numPages));
  };

  return {
    isLoading,
    pageFiles: downloadUrlModel?.presentationPageFileUrlModels,
    title: downloadUrlModel?.presentationTitle,
    totalPages: downloadUrlModel?.presentationPageFileUrlModels.length ?? 0,
    handleLoadSuccess,
  };
};

export default usePresentationDocument;
