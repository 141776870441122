import classNames from "classnames";
import styles from "./DragPreviewSection.module.scss";

type DragPreviewSectionProps = {
  sectionName: string;
};

const DragPreviewSection = ({ sectionName } : DragPreviewSectionProps) => {
  return <div className={classNames("px-3 py-1", styles.container)}>
    <p className={styles["margin-0"]}>
      {sectionName}
    </p>
  </div>;


};

export default DragPreviewSection;