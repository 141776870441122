import * as yup from "yup";

import { EmployeeModel } from "@services/api/employee/models/employeeModel";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";
import { CreateEditEmployeeModel } from "@services/api/employee/models/createEditEmployeeModel";
import { profileValidation } from "@components/validation/sharedValidation/profileValidation";

const phonenumberRegex = "^((?:(\\+?[0-9]){0}|\\+?[0-9]{2})?\\s?)?[0-9]{8}$";

const employeeValidation = (localizer: Localizer) => createValidationSchema<EmployeeModel>({
  id: yup.string(),
  initials: yup.string(),
  phone: yup.string().matches(RegExp(phonenumberRegex), { message: localizer.phoneInvalid(), excludeEmptyString: true }).nullable(),
  active: yup.boolean().required(localizer.activeRequired()),
  userProfile: profileValidation(localizer),
  accessRights: yup.array().required(),
  caseAccessRights: yup.array().required(),
});

export const createEditEmployeeValidation = (localizer: Localizer) => createValidationSchema<CreateEditEmployeeModel>({
  employee: employeeValidation(localizer),
  profilePictureUrl: yup.string().nullable(),
  isTheOnlyAdministrativeEmployee: yup.boolean(),
  isTheOnlySystemAdmin: yup.boolean(),
});

