import { useEffect } from "react";
import Card from "@components/embla/card";
import { useLocalization } from "@components/localization/localizationProvider";
import MarkingCloseBtn from "../shared/markingCloseBtn";
import { FocusBoxMarkingModel } from "../../../models/markingModel";
import { EditCardProps } from "../shared/cardProps.model";
import MarkingMetaBlock from "../shared/markingMetaBlock";
import useMarkingCardForm from "../shared/useMarkingCardForm";
import MarkingTagsDropdown from "../shared/markingTagsDropdown";
import MarkingDeleteBtn from "../shared/markingDeleteBtn";
import styles from "./focusBoxEdit.module.scss";

const FocusBoxEdit = ({
  marking,
  onMarkingChange,
  onSave,
  onDelete,
  onClose,
  isTemplate,
}: EditCardProps<FocusBoxMarkingModel>) => {
  const localizer = useLocalization();
  const markingCantBeDeleted = marking.presentationList && marking.presentationList.length > 0;

  const { methods, submitHandler } = useMarkingCardForm({
    marking,
    onMarkingChange,
    onSave,
    defaultValues: {
      tags: marking.tags,
    },
  });

  const { setFocus } = methods;
  useEffect(() => setFocus("tags"), [setFocus]);

  return (
    <Card className={styles.container}>
      <Card.Header>
        {localizer.focusBoxTitle()}
        <MarkingCloseBtn close={onClose} />
      </Card.Header>
      <Card.Body>
        <MarkingMetaBlock marking={marking} />
        <form onSubmit={submitHandler}>
          <MarkingTagsDropdown
            methods={methods}
            id={`focus-box-tags-${marking.id}`}
            name="tags"
          />
        </form>
      </Card.Body>
      {!isTemplate &&
        <Card.Footer>
          <MarkingDeleteBtn cantBeDeleted={markingCantBeDeleted ?? false} onDelete={onDelete}/>
        </Card.Footer>
      }
    </Card>
  );
};

export default FocusBoxEdit;

