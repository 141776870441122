import { useEffect, useRef } from "react";
import { Event } from "@infrastructure/Event";

interface UseShortcutProps {
  key: string;
  modifier?: boolean; // Ctrl on Windows/Linux or Command on MacOS
  alt?: boolean;
  callback: () => void;
  allowOnInputs?: boolean;
}

const useShortcut = ({
  key,
  alt = false,
  modifier = false,
  callback,
  allowOnInputs,
}: UseShortcutProps) => {
  // keep reference to callback to avoid recreating effect on each render
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (
        key === e.key
        && alt === e.altKey
        && modifier === (e.ctrlKey || e.metaKey)
        && (modifier || allowOnInputs || !isFormInput(e.target as Element))
      ) {
        callbackRef.current();
        e.preventDefault();
      }
    };
    document.addEventListener(Event.Keydown, listener);
    return () => document.removeEventListener(Event.Keydown, listener);
  }, [key, alt, modifier, allowOnInputs]);
};


const isFormInput = (el: Element) =>
  /^(input|textarea|select)$/i.test(el.nodeName) ||
  (el.attributes[0].nodeName === "contenteditable" && el.classList.contains("tui-editor-contents")); //catches embla richTextEditor

export default useShortcut;
