import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useGetPresentationPagesQuery } from "@services/api/presentation/presentationApi";
import usePresentationDocument from "@pages/present/usePresentationDocument";
import { Spinner } from "@components/spinner/spinner";
import EditPresentationPages from "./editPresentationPages";
import styles from "./editPresentationPage.module.scss";

const EditPresentationPage = () => {
  const { seqId, pageId, caseId, present } = useParams();
  if (!seqId || !pageId || !caseId) {
    throw new Error("\"seqId\", \"pageId\", \"caseId\" params are required");
  }

  const [pollingIntervalSeconds, setPollingIntervalSeconds] = useState(4);
  const { data, isLoading } = useGetPresentationPagesQuery(seqId, { pollingInterval: pollingIntervalSeconds * 1000 });

  const { pageFiles, handleLoadSuccess, title } = usePresentationDocument(seqId, pollingIntervalSeconds * 1000);

  const pageFile = pageFiles?.find((p) => p.presentationPageId === pageId);
  const pageData = data?.find(({ id }) => id === pageId);

  const sequenceIsReady = data && pageData && pageFile;

  useEffect(() => {
    if (sequenceIsReady) {
      // After we get downloadUrl, only poll when token is about to expire
      setPollingIntervalSeconds(60 * 10);
    }
  }, [sequenceIsReady]);

  if (isLoading || !data || !pageData || !pageFile) {
    return <Spinner />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classNames("d-flex flex-column", styles.container)}>
        {data && pageData && pageFile && (
          <EditPresentationPages
            presentationId={seqId}
            pageId={pageId}
            caseId={caseId}
            pageFile={pageFile}
            pageData={pageData}
            data={data}
            title={title}
            handleLoadSuccess={handleLoadSuccess}
            present={present === "present"}
          />
        )}
      </div>
    </DndProvider>
  );
};

export default EditPresentationPage;
