import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PDFDocumentProxy } from "pdfjs-dist";
import classNames from "classnames";
import { NotificationModule } from "ditmer-embla";
import { createVerticalStrength, useDndScrolling } from "react-dnd-scrolling";
import { debounce } from "lodash";
import BottomBar from "@components/bottomBar/bottomBar";
import EditPresentationTitlePage from "@components/case/presentationPage/editPresentationTitlePage";
import { useLocalization } from "@components/localization/localizationProvider";
import PagesControl from "@components/pagesControl/pagesControl";
import Toolbar from "@components/toolbar/toolbar";
import { Key } from "@infrastructure/Key";
import { PresentationInfo } from "@pages/pdfviewer/component/models/presentationInfo";
import SinglePagePdfView from "@pages/pdfviewer/component/singlePagePdfView";
import PresentPage from "@pages/present/presentPage";
import useShortcut from "src/hooks/useShortcut";
import InlineSwitch from "@components/checkbox/inlineSwitch";
import usePageMarkings from "@pages/pdfviewer/component/page/canvas/usePageMarkings";
import { RoutePaths } from "@components/routing/routes";
import { presentationsTabId } from "@pages/case/casePage";
import { PresentationPageFileUrlModel } from "@services/api/case/models/PresentationFileUrlViewModel";
import { CasePresentationPageModel, CasePresentationPageNoteModel } from "@services/api/case/models/casePresentationPageModel";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { AddButton } from "@components/button/addButton/addButton";
import {
  useEditPresentationPageMarkingsMutation,
  useChangePageOrientationMutation,
  useMovePresentationNoteMutation,
} from "@services/api/casePresentation/casePresentationApi";
import { PresentationSpeakerNoteCreateEditCard } from "@pages/case/presentations/editPresentationPages/presentationCrud/presentationSpeakerNoteCreateEditCard";
import Card from "@components/embla/card";
import Button from "@components/embla/button";
import { ReactComponent as FlipIcon } from "@content/icons/flip-down.svg";
import { dndItemTypes } from "@pages/extractCompositionPage/components/ExtractSections/DndItemTypes";
import { Tooltip } from "@components/tooltip/tooltip";
import MarkdownRenderer from "@components/markdown/markdownRenderer";
import MarkdownSizeButtons from "@components/markdown/markdownSizeButtons";
import { useConfig } from "@components/config/configProvider";
import DragNDropIndicator, { DragNDropIndicatorPosition } from "@components/dnd/DragNDropIndicator/DragNDropIndicator";
import { useGetPresentationPagesQuery } from "@services/api/presentation/presentationApi";
import DragLayer from "@components/dnd/DragNDropIndicator/DragLayer/DragLayer";
import useElectronApi from "../../../../hooks/useElectronApi";
import styles from "./editPresentationPages.module.scss";


type EditPresentationPagesProps = {
  presentationId: string;
  pageId: string;
  caseId: string;
  pageFile: PresentationPageFileUrlModel;
  pageData: CasePresentationPageModel;
  data: CasePresentationPageModel[];
  title: string | undefined;
  handleLoadSuccess: (pdf: PDFDocumentProxy) => void;
  present: boolean;
};

const EditPresentationPages = ({ presentationId, pageId, caseId, pageFile, pageData, data, title, handleLoadSuccess, present }: EditPresentationPagesProps) => {
  const config = useConfig();

  const [previewMode, setPreviewMode] = useState(false);
  const [showDrawer, setShowDrawer] = useState(true);
  const [redirectAppUrl, setRedirectAppUrl] = useState<string>();
  const [editSpeechnoteState, setEditSpeechnoteState] = useState<CasePresentationPageNoteModel | undefined>();
  const [createSpeechnoteState, setCreateSpeechnoteState] = useState<boolean>(false);
  const [selectedMarkingsState, setSelectedMarkingsState] = useState<{ pageId: string; ids: string[] }>();
  
  const navigate = useNavigate();
  const location = useLocation();
  const localizer = useLocalization();
  const electronApi = useElectronApi();

  const notesContainerRef = useRef<HTMLDivElement>(null);

  const [editMarkings, { isLoading: isLoadingEditMarkings }] = useEditPresentationPageMarkingsMutation();
  const [changeOrientation, { isLoading: isLoadingChangeOrientation }] = useChangePageOrientationMutation();

  const sortedNotes = useMemo(() => [...pageData.notes].sort((a, b) => a.order - b.order), [pageData.notes]);

  // clear user selected markings when page changes
  useEffect(() => setSelectedMarkingsState(undefined), [pageId]);
  // show only this page's markings to avoid autosave triggered by setting new value by effect
  const selectedMarkings = selectedMarkingsState?.pageId === pageId ? selectedMarkingsState.ids : pageData?.markingIds;

  const { markings: allPageMarkings } = usePageMarkings(pageData.order);

  const presentationInfo: PresentationInfo = useMemo(() => ({ id: presentationId, title: title ?? "" }), [presentationId, title]);

  useShortcut({ key: Key.Escape, callback: () => previewMode && setPreviewMode(false) });

  const [reorderNote, { isLoading: isLoadingReorder }] = useMovePresentationNoteMutation();
  const { isFetching: isFetchingPresentation } = useGetPresentationPagesQuery(presentationId);
  
  const linearVerticalStrength = createVerticalStrength(150);
  useDndScrolling(notesContainerRef, { verticalStrength: linearVerticalStrength });

  // Used when deeplink to elektron app and we want the presentation to start automaticaly
  useEffect(() => {
    if (electronApi && present) {
      electronApi.present(presentationInfo.id);
    }
  }, [electronApi, present, presentationInfo.id]);

  useEffect(() => {
    setRedirectAppUrl(`${config.appProtocol}:/${RoutePaths.casePresentationEditPresent.url(caseId, presentationId, pageId)}`);
  }, [caseId, config.appProtocol, pageId, presentationId]);


  if (!data || !pageData || !pageFile) {
    return null;
  }

  const changePage = (newPageIndex: number) => {
    const newPage = data.find(({ order }) => order === newPageIndex);
    if (newPage) {
      navigate(`../edit/${newPage.id}`, { replace: true });
    }
  };

  const onPageRemoved = (removedPageId: string) => {
    if (pageId === removedPageId) {
      if (data.length === 1) {
        navigate(RoutePaths.caseDetails.url(caseId, presentationsTabId));
      } else {
        const current = pageData.order;
        changePage(current >= data.length - 1 ? current - 1 : current + 1);
      }
    }
  };

  const reorder = async (droppedNote: CasePresentationPageNoteModel, position: DragNDropIndicatorPosition, droppedIndex?: number) => {
    if (droppedNote.order === droppedIndex || droppedIndex === undefined || (droppedNote.order === droppedIndex + 1 && position === "below")) {
      return;
    }
    const model = {
      newSortOrder: droppedIndex,
      noteId: droppedNote.id,
    };

    if (droppedNote.order < droppedIndex && position === "above") {
      model.newSortOrder -= 1;
    }

    reorderNote({ caseId, presentationId: presentationInfo.id, presentationPageId: pageData.id, model });
  };

  if (previewMode) {
    return (
      <PresentPage
        pdfUrl={pageFile.downloadUrl}
        pageIndex={0}
        onLoadSuccess={handleLoadSuccess}
        pageData={pageData}
        isLoading={false}
        onClick={() => setPreviewMode(false)}
      />
    );
  }

  const updateMarkings = (markingIds: string[]) => {
    const previousMarkings = markingIds;
    setSelectedMarkingsState({ pageId, ids: markingIds });
    editMarkings({ caseId: caseId, presentationId: presentationInfo.id, presentationPageId: pageData.id, model: { markingIds: markingIds } })
      .unwrap()
      .then(() => {
        NotificationModule.showSuccessSmall(localizer.presentationEditted());
      })
      .catch(() => {
        setSelectedMarkingsState({ pageId, ids: previousMarkings });
      });
  };

  const onChangeOrientation = debounce(() => {
    const newOrientation = !pageData.orientation ? 90 : pageData.orientation === 270 ? 0 : pageData.orientation + 90;
    changeOrientation({
      caseId: caseId,
      presentationId: presentationInfo.id,
      presentationPageId: pageData.id,
      model: { orientation: newOrientation },
    }).unwrap();
  }, 200);

  const handleBackAction = () => {
    const query = new URLSearchParams(location.search);
    const back = query.get("back");

    if (back === "doc") {
      navigate(-1);
      return "";
    }
    
    return RoutePaths.casePresentation.url(caseId, presentationId);
  };
  
  return (
    <div className={classNames("d-flex flex-column", styles.container)}>
      <Toolbar
        backAction={handleBackAction}
        subTitle={pageData && !pageData.isTitlePage ? `${localizer.pdfPage()} ${pageData.pageIndex + 1}` : undefined}
        title={pageData.isTitlePage ? localizer.presentationTitlePage() : pageData.fileName}
      >
        <Toolbar.Item>
          <div className="py-1">
            <Tooltip message={allPageMarkings.length > 0 && localizer.cantRotatePageWhenMarkings()}>
              <Button
                borderless
                className={classNames("btn btn-default btn-icon")}
                disabled={allPageMarkings.length > 0 || isLoadingChangeOrientation || isFetchingPresentation}
                onClick={onChangeOrientation}
              >
                <FlipIcon />
              </Button>
            </Tooltip>
          </div>
        </Toolbar.Item>
        <Toolbar.Item>
          <div className="py-1">
            <InlineSwitch id="show-overview" checked={showDrawer} onChange={(e) => setShowDrawer(e.currentTarget.checked)} />
            <label htmlFor="show-overview">{localizer.showDrawer()}</label>
          </div>
        </Toolbar.Item>
        <Toolbar.Item label={localizer.steps()}>
          <PagesControl currentPageIndex={pageData.order} totalPages={data.length} onPageChange={changePage} alternativeDirections />
        </Toolbar.Item>
      </Toolbar>
      <div className="flex-1 container-fluid overflow-hidden">
        <div className="row h-100">
          <div className="col-8 pl-0 overflow-hidden d-none d-sm-block flex-shrink-0">
            {pageFile && (
              <SinglePagePdfView
                pdfUrl={pageFile?.downloadUrl}
                docPageIndex={pageData.order}
                pdfPageIndex={0}
                pageMargins={16}
                orientation={pageData.orientation}
                onDocumentLoadSuccess={handleLoadSuccess}
                presentedMarkings={selectedMarkings}
                onPresentationMarkingsChange={(ids) => updateMarkings(ids)}
                isLoadingPresentationMarkingsChange={isLoadingEditMarkings}
                disableEditing
                hideCards={allPageMarkings === undefined || allPageMarkings.length === 0}
              />
            )}
          </div>
          <div className={classNames("col pt-3 h-100 overflow-hidden shadow-2", styles.speakerNotesContainer)}>
            {pageData.isTitlePage && (
              <div className="mb-5">
                <h3>{localizer.presentationTitlePage()}</h3>

                <EditPresentationTitlePage
                  caseId={caseId}
                  presentationId={presentationId}
                  presentationPageId={pageId}
                  titlePageHeader={pageData.titlePageTitle}
                  titlePageContentText={pageData.titlePageDescription}
                />
              </div>
            )}

            <div className="d-flex justify-content-between mb-3">
              <h3>
                {localizer.speakerNotes()} <EmblaIcon iconName={"info"} />
              </h3>
              <span className="d-flex gap-s align-items-center">
                <MarkdownSizeButtons presentationId={presentationInfo.id} presentationPageId={pageData.id} caseId={caseId} noteSize={pageData.noteSize} />
                <AddButton
                  fixedPosition={false}
                  defaultSize
                  onClick={() => {
                    setCreateSpeechnoteState(true);
                    setEditSpeechnoteState(undefined);
                  }}
                />
              </span>
            </div>

            <div className={classNames(createSpeechnoteState ? styles.speakerNoteCreateCardVisible : styles.speakerNoteCreateCardHidden)}>
              <PresentationSpeakerNoteCreateEditCard
                presentationId={presentationInfo.id}
                pageId={pageId}
                caseId={caseId}
                key={pageData.id}
                note={editSpeechnoteState}
                onActionFinishedCallback={() => setCreateSpeechnoteState(false)}
                onDiscardCallback={() => setCreateSpeechnoteState(false)}
                index={0}
              />
            </div>
            <div
              ref={notesContainerRef}
              className={classNames("scrollbar overflow-auto", styles.speakerNoteContainer, (isFetchingPresentation || isLoadingReorder) && styles.disabled)}
            >
              {pageData &&
                sortedNotes.map((note, index) => (
                  <DragNDropIndicator disableDrag={!!editSpeechnoteState} droppedIndex={index} item={note} onDrop={reorder} dndType={dndItemTypes.speakerNote} key={note.id}>
                    {editSpeechnoteState?.id === note.id ? (
                      <PresentationSpeakerNoteCreateEditCard
                        key={note.id}
                        presentationId={presentationInfo.id}
                        pageId={pageId}
                        caseId={caseId}
                        note={note}
                        onActionFinishedCallback={() => setEditSpeechnoteState(undefined)}
                        onDiscardCallback={() => setEditSpeechnoteState(undefined)}
                        index={index + 1}
                      />
                    ) : (
                      <Card
                        className="my-2"
                        onClick={() => {
                          setEditSpeechnoteState(note);
                          setCreateSpeechnoteState(false);
                        }}
                        role={"button"}
                      >
                        <Card.Body>
                          <MarkdownRenderer size={pageData.noteSize}>{note.note}</MarkdownRenderer>
                        </Card.Body>
                      </Card>
                    )}
                  </DragNDropIndicator>
                ))}
            </div>
          </div>
        </div>
      </div>
      {showDrawer && (
        <BottomBar activePresentation={presentationInfo} activePageId={pageId} redirectAppUrl={redirectAppUrl} onPageRemoved={onPageRemoved} presentationMode />
      )}
      <DragLayer dndType={dndItemTypes.speakerNote}/>
    </div>
  );
};

export default EditPresentationPages;
