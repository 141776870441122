import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Document, pdfjs } from "react-pdf";
import { useLocation, useParams } from "react-router-dom";
import {
  GridOnItemsRenderedProps,
  ListOnItemsRenderedProps,
  VariableSizeList,
} from "react-window";
import { debounce } from "lodash";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import useElementSize from "src/hooks/useElementSize";
import BottomBar from "@components/bottomBar/bottomBar";
import { useLocalization } from "@components/localization/localizationProvider";
import { Spinner } from "@components/spinner/spinner";
import { useAppDispatch, useAppSelector } from "@hooks";
import { PdfViewportElementProvider } from "@pages/pdfviewer/component/hooks/pdfViewportElementProvider";
import { useVisiblePages } from "@pages/pdfviewer/component/hooks/useVisiblePages";
import { PageList } from "@pages/pdfviewer/component/page/pageRenderer";
import { useGetDocumentPagesQuery } from "@services/api/document/caseDocumentApi";
import { PdfSidebar } from "@pages/pdfviewer/component/pdfSidebar/pdfSidebar";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";
import {
  PdfType,
  initPdfViewer,
  mostVisiblePageIndexSelector,
  pdfViewerStateSelector,
  setActivePresentation,
  setGoToIndex,
  setTotalPages,
} from "@pages/pdfviewer/component/pdfViewerSlice";
import DocumentToolbar from "@pages/pdfviewer/component/toolbar/documentToolbar";
import { useGetCasePresentationsQuery } from "@services/api/casePresentation/casePresentationApi";
import useElectronApi from "src/hooks/useElectronApi";
import { CaseDocumentSettingsModel } from "@infrastructure/storageModels";
import JoyrideOverlay, { JoyridePage } from "@components/joyrideOverlay/joyrideOverlay";
import { StorageKeys } from "@infrastructure/storageKeys";
import { isProduction } from "src/utility/env";
import { RoutePaths } from "@components/routing/routes";
import { useConfig } from "@components/config/configProvider";
import getPageDimensions from "./utils/getPageDimensions";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import styles from "./pdfViewer.module.scss";
import { PageDimensions, PageDimensionsArray } from "./pageDimensions";
import useMarkingClickOutside from "./page/canvas/useMarkingClickOutside";
import { PdfSizeProvider } from "./hooks/pdfSizeProvider";


// see: https://github.com/wojtekmaj/react-pdf/issues/1811
// @ts-expect-error This does not exist outside of polyfill which this is doing
if (typeof Promise.withResolvers === "undefined") {
  if (window)
  // @ts-expect-error This does not exist outside of polyfill which this is doing
    window.Promise.withResolvers = () => {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };

  if (isProduction) {
    pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/legacy/pdf.worker.min.mjs`;
  } else {
    pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/legacy/pdf.worker.mjs`;
  }
} else {
// See https://github.com/wojtekmaj/react-pdf/blob/main/packages/react-pdf/README.md
  if (isProduction) {
    pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;
  } else {
    pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;
  }
}

export const pageMargin = 20;


interface NavigateToPageProps {
  navigateToPageIndex: number,
}

interface PdfViewerProps {
  caseId: string;
  documentId: string;
  fileUrl: string;
  title: string;
  subTitle: string;
}

const saveCaseDocumentSettings = (caseDocumentId: string, latestPageIndex: string ) => {
  const key = StorageKeys.caseDocumentSettingsStorage(caseDocumentId);
  let newCaseDocumentSettings: CaseDocumentSettingsModel = {};

  const caseSettings = localStorage.getItem(key);
  if (caseSettings !== null) {
    const caseDocumentSettingsFromStorage: CaseDocumentSettingsModel  = JSON.parse(caseSettings);
    newCaseDocumentSettings = { ...caseDocumentSettingsFromStorage, latestPageIndex: latestPageIndex } ;
  } else {
    newCaseDocumentSettings = { latestPageIndex: latestPageIndex } ;
  }
  localStorage.setItem(key, JSON.stringify(newCaseDocumentSettings));
};

const getPdfViewerLatestPageIndex = (caseDocumentId: string ): string => {
  const key = StorageKeys.caseDocumentSettingsStorage(caseDocumentId);
  const pdfViewerPageIndex = localStorage.getItem(key);
  let caseDocumentSettingsFromStorage: CaseDocumentSettingsModel  = {};
  if (pdfViewerPageIndex !== null) {
    caseDocumentSettingsFromStorage  = JSON.parse(pdfViewerPageIndex);
  }

  return caseDocumentSettingsFromStorage.latestPageIndex ?? "0";
};


const PdfViewer = (props: PdfViewerProps) => {
  const [items, setItems] = useState<ListOnItemsRenderedProps | GridOnItemsRenderedProps>();
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [visiblePages] = useVisiblePages(items);
  const [documentWrapperRef, documentWrapperSize] = useElementSize();
  const [pdf, setPdf] = useState<DocumentCallback>();
  const pdfViewerState = useAppSelector(pdfViewerStateSelector);
  const [previousScale, setPreviousScale] = useState(pdfViewerState.scale);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [runJoyride, setRunJoyride] = useState(false);
  const [redirectAppUrl, setRedirectAppUrl] = useState<string>();
  const location = useLocation();
  const config = useConfig();
  useMarkingClickOutside();

  const { initialPageNumber, seqId } = useParams();
  const mostVisiblePageIndex = useAppSelector(mostVisiblePageIndexSelector);
  const localizer = useLocalization();
  const dispatch = useAppDispatch();

  const [primaryList, setprimaryList] = useState<VariableSizeList | null>();
  const pdfViewportRef = useRef<HTMLDivElement>(null);

  const { data: pagesInfo } = useGetDocumentPagesQuery(
    { caseId: props.caseId, documentId: props.documentId },
  );

  useEffect(() => {
    if (location?.state) {
      const navigateToPageIndex = (location.state as NavigateToPageProps).navigateToPageIndex;
      if (navigateToPageIndex !== undefined && navigateToPageIndex > 0) {
        dispatch(setGoToIndex(navigateToPageIndex));
      }
    }
  }, [dispatch, location, initialPageNumber]);

  useEffect(() => {
    let pageIndex = 0 ;
    if (initialPageNumber) {
      pageIndex = parseInt(initialPageNumber) - 1;
    } else {
      pageIndex = parseInt(getPdfViewerLatestPageIndex(props.documentId));
    }
    dispatch(setGoToIndex(pageIndex));
  }, [dispatch, initialPageNumber, props.documentId, isDocumentLoaded]);

  const saveCaseDocumentSettingsDebounced = debounce(()=> {
    saveCaseDocumentSettings(props.documentId, pdfViewerState.mostVisiblePageIndex.toString());
  }, 1000);

  useEffect(() => {
    saveCaseDocumentSettingsDebounced();
    return () => {
      saveCaseDocumentSettingsDebounced.cancel();
    };
  }, [pdfViewerState.mostVisiblePageIndex, props.documentId, saveCaseDocumentSettingsDebounced]);

  useEffect(() => {
    dispatch(initPdfViewer({
      title: props.title,
      caseId: props.caseId,
      pdfTypeId: props.documentId,
      pdfType: PdfType.CaseDocument,
    }));
  }, [dispatch, props.title, props.documentId, props.caseId]);

  const scrollToPage = useCallback((pageIndexLocal: number) => {
    primaryList?.scrollToItem(pageIndexLocal, "start");
  }, [primaryList]);

  useEffect(() => {
    if (pdfViewerState.goToPageIndex !== undefined && primaryList !== null && primaryList !== undefined) {
      scrollToPage(pdfViewerState.goToPageIndex);
      dispatch(setGoToIndex(undefined));
    }
  }, [dispatch, pdfViewerState.goToPageIndex, primaryList, scrollToPage]);

  const onDocumentLoadSuccess = (pdfProxy: DocumentCallback) => {
    dispatch(setTotalPages(pdfProxy.numPages));
    setPdf(pdfProxy);
  };

  const pageOrientations : PageOrientation[] | undefined = useMemo(() => pagesInfo?.map((p) => p.pageOrientation), [pagesInfo]);
  
  const [pdfDimensions, setPdfDimensions] = useState<PageDimensionsArray>();
  useEffect(() => {
    if (pdf && pageOrientations) {
      const promises = Array
        .from({ length: pdf.numPages })
        .map((_, i) => pdf.getPage(i + 1));

      Promise.all(promises).then((values) => {
        setPdfDimensions(values.map((p, i) => getPageDimensions(p, pageOrientations?.[i] ?? 0)));
      });
    }
  }, [pdf, pageOrientations]);

  const scaledDimensions = useMemo(
    () =>
      pdfDimensions?.map(
        ({ width, height, rotation }): PageDimensions => ({
          width: width * pdfViewerState.scale,
          height: height * pdfViewerState.scale,
          rotation,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pdfDimensions, pdfViewerState.scale, pagesInfo],
  );

  useEffect(() => {
    if (previousScale !== pdfViewerState.scale) {
      // Calculate new scroll position after applying scale. Since page margin isn't scaled, we need to subtract the page margins from the X scroll position, calculate the new X scroll position, and then add the page margins again
      const lastVisiblePageIndex = visiblePages[visiblePages.length - 1];
      const visiblePagesCount = lastVisiblePageIndex + 1;
      const visiblePagesTotalMargin = visiblePagesCount * pageMargin;
      const currentScrollWithoutMargin = currentScroll - visiblePagesTotalMargin;
      const newScrollWithoutMargin = currentScrollWithoutMargin / previousScale * pdfViewerState.scale;
      const newScroll = newScrollWithoutMargin + visiblePagesTotalMargin;

      primaryList?.resetAfterIndex(0);
      primaryList?.scrollTo(newScroll);

      setPreviousScale(pdfViewerState.scale);
    }
  }, [pdfViewerState.scale, previousScale, currentScroll, visiblePages, primaryList]);

  // Used when deeplink to electron app and we want the presentation to start automatically
  const electronApi = useElectronApi();
  const { data: casePresentations } = useGetCasePresentationsQuery(props.caseId);
  useEffect(() => {
    if (seqId && casePresentations) {
      const selectedPresentation = casePresentations.find((x) => x.id === seqId);
      if (selectedPresentation) {
        dispatch(setActivePresentation({ id: selectedPresentation?.id, title: selectedPresentation.title }));
        electronApi?.present(selectedPresentation?.id);
      }
    }
  }, [casePresentations, dispatch, seqId, electronApi]);

  // Refresh the list and scroll to center of page when the dimensions change
  useEffect(() => {
    primaryList?.resetAfterIndex(0, true);
    primaryList?.scrollToItem(mostVisiblePageIndex, "center");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaledDimensions, primaryList]);

  const getListItemHeight = (index: number): number => {
    const lastPageMargin = index === pdfViewerState.totalPages - 1 ? pageMargin : 0;
    return (scaledDimensions?.[index].height ?? 0) + pageMargin + lastPageMargin;
  };

  useEffect(() => {
    const url = () => {
      if (props.documentId && pdfViewerState.activePresentation?.id) {
        return `${config.appProtocol}:/${RoutePaths.casePresentInDocument.url(props.caseId, props.documentId, pdfViewerState.activePresentation?.id)}`;
      }
      return "";
    };
    setRedirectAppUrl(url());
  }, [config.appProtocol, pdfViewerState.activePresentation?.id, props.caseId, props.documentId]);

  return (
    <div className={styles.documentViewer}>
      <JoyrideOverlay
        run={runJoyride}
        joyridePage={JoyridePage.DocumentViewer}
        joyrideFinishedCallback={() => setRunJoyride(false)}
      />
      <DocumentToolbar
        pdf={pdf}
        title={props.title}
        pdfViewSize={documentWrapperSize}
        subTitle={props.subTitle}
        visiblePageIndexes={visiblePages}
        runJoyrideCallback={() => setRunJoyride(true)}
      />
      <DndProvider backend={HTML5Backend}>
        <div className={classNames("relative", styles.documentViewport)}>
          {pdfDimensions &&
          <PdfSidebar
            fileUrl={props.fileUrl}
            pdfContainerHeight={documentWrapperSize.height}
            pdfDimensions={pdfDimensions}
            documentId={props.documentId}
          />
          }
          <div
            className="h-100"
            ref={documentWrapperRef}>
            <Document
              onItemClick={({ pageNumber }) => scrollToPage(Number(pageNumber) - 1)}
              externalLinkTarget="_blank"
              error={
                <h2 className={"d-flex justify-content-center margin-top-m"}>
                  {localizer.pdfNotFound()}
                </h2>
              }
              loading={
                <div className={"d-flex justify-content-center margin-top-m"}>
                  <Spinner text={localizer.pdfDownloading()} />
                </div>
              }
              className="w-100"
              file={props.fileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {scaledDimensions &&
              <PdfSizeProvider pdfWidth={scaledDimensions[0].width}>
                <PdfViewportElementProvider pdfViewportElement={pdfViewportRef.current}>
                  <VariableSizeList
                    height={documentWrapperSize.height}
                    itemCount={pdfViewerState.totalPages}
                    itemSize={getListItemHeight}
                    estimatedItemSize={getListItemHeight(0)}
                    width={"100%"}
                    onScroll={(s) => setCurrentScroll(s.scrollOffset)}
                    itemData={{
                      pdfDimensions: scaledDimensions,
                      visiblePages,
                      pageMargin,
                      scale: pdfViewerState.scale,
                      renderAnnotationLayer: true,
                      pageOrientations: pageOrientations,
                      onDocumentLoaded: () => setIsDocumentLoaded(true),
                    }}
                    onItemsRendered={setItems}
                    ref={setprimaryList}
                    outerRef={pdfViewportRef}
                    useIsScrolling
                  >
                    {PageList}
                  </VariableSizeList>
                </PdfViewportElementProvider>
              </PdfSizeProvider>
              }
            </Document>
          </div>
        </div>
        <BottomBar
          activePresentation={pdfViewerState.activePresentation}
          bulkAddState={pdfViewerState.bulkAdd}
          activePageId={{ index: pdfViewerState.mostVisiblePageIndex, caseDocumentId: pdfViewerState.pdfTypeId }}
          redirectAppUrl={redirectAppUrl}
          pdf={pdf}
          pdfViewSize={documentWrapperSize}
        />
      </DndProvider>
    </div >
  );
};


export default PdfViewer;
