import React from "react";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal from "@components/modal/modal";
import { convertMarkingTypeToString, MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { ExportBaseModel } from "@services/api/case/models/exportBaseModel";
import Button from "@components/embla/button";
import ExportBaseForm from "@components/Export/exportBaseForm";
import { useGetCaseParticipantsQuery } from "@services/api/participant/caseParticipantApi";
import { useExportPresentationMutation } from "@services/api/casePresentation/casePresentationApi";
import { ExportPresentationModel } from "@services/api/case/models/exportPresentationModel";
import { ExportBaseFormFields } from "@components/Export/exportBaseFormFields";
import FormSwitch from "@components/forms/formSwitch";

type ExportPresentationProps = {
  caseId: string;
  presentationId: string;
  presentationTitle: string;
  onExportResult: (presentationExportId: string) => void;
  onBackClicked: () => void;
};

const ExportPresentation = ({
  caseId,
  presentationId,
  presentationTitle,
  onExportResult,
  onBackClicked,
}: ExportPresentationProps) => {
  const localizer = useLocalization();
  const [ exportPresentation, exportPresentationRequest ] = useExportPresentationMutation();
  const { data: participants, isLoading: participantsIsLoading } = useGetCaseParticipantsQuery({ caseId });
  const formFieldIdPrepend = "export-presentation-";

  const initialValues: ExportPresentationModel = {
    markingOwners: [],
    markingTypes: [ MarkingType.Comment, MarkingType.Highlight, MarkingType.VerticalLines, MarkingType.FocusBox ],
    includeSpeechNotes: false,
  };

  const methods = useValidatedForm({
    defaultValues: initialValues,
  });

  const onSubmitExportPresentation = async (model: ExportPresentationModel) => {
    const result = await exportPresentation({ caseId, presentationId, model }).unwrap();

    if (result) {
      onExportResult(result);
    }
  };

  return (
    <>
      <Modal.Header>{`${localizer.exportPresentation()}: ${presentationTitle}`}</Modal.Header>
      <Modal.Body>
        <div className="subtle">{localizer.exportPresentationHint1()}</div>
        <div className="subtle">{localizer.exportPresentationHint2()}</div>

        <form>
          <ExportBaseFormFields methods={methods} fieldIdPrepend={formFieldIdPrepend} participants={participants} excludeComments={true} />

          <FormSwitch
            methods={methods}
            id={`${formFieldIdPrepend}-include-speechnotes`}
            label={localizer.includeSpeechNotes()}
            name="includeSpeechNotes"
            overwriteMarginTopBottom="l"
            defaultLabelMarginLeft
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onBackClicked}>{localizer.cancel()}</Button>
        <FormSubmitButton
          state={{
            isSubmitting: exportPresentationRequest.isLoading,
            isSubmitSuccessful: exportPresentationRequest.isSuccess,
          }}
          isPrimary={true}
          onClick={methods.handleSubmit(onSubmitExportPresentation)}
          disabled={participantsIsLoading}
        >
          {localizer.exportVerb()}
        </FormSubmitButton>
      </Modal.Footer>
    </>
  );
};

export default ExportPresentation;
