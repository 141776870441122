import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import { CircularProgressbarStyles } from "react-circular-progressbar/dist/types";
import { Alert } from "antd";
import { useLocalization } from "@components/localization/localizationProvider";
import {
  useGetExtractDraftExportDocumentQuery,
  useLazyGetExtractDraftExportDocumentQuery,
} from "@services/api/extractDraft/extractDraftApi";
import { EmblaIllustration, IllustrationColor } from "@components/embla/emblaIllustration/emblaIllustration";
import { EmblaIcon, IconColor, IconSize } from "@components/embla/emblaIcon";
import { Spinner } from "@components/spinner/spinner";
import { RoutePaths } from "@routes";
import baseApi, { apiTags } from "@services/api/baseApi";
import { ExtractDraftExportHubEventType } from "@services/signalRClient/extractDraftExportHubConnectionManager";
import { HubConnectionType } from "@services/signalRClient/hubConnectionFactory";
import { CompleteExtractResponseModel } from "@services/api/extractDraft/models/completeExtractResponseModel";
import {
  ExtractDraftExportEventModel,
} from "@pages/extractCompositionPage/components/export/extractDraftExportEventModel";
import useHubConnection from "../../../../hooks/useHubConnection";
import styles from "./downloadExtractDraftExport.module.scss";

interface NavigateExtractDraftDocumentProps {
  caseId: string;
  extractId: string;
  extractExportId: string;
}

export const NavigateExtractDraftDocument = ({ caseId, extractId, extractExportId }: NavigateExtractDraftDocumentProps) => {
  const localizer = useLocalization();
  const dispatch = useDispatch();

  const [hubConnectionManager] = useState(useHubConnection(HubConnectionType.ExtractDraftExport));
  const [getExportDocument] = useLazyGetExtractDraftExportDocumentQuery();

  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [exportDocument, setExportDocument] = useState<CompleteExtractResponseModel | undefined>(undefined);
  const [isError, setIsError] = useState(false);


  const extractDocumentIsReady = !!(exportDocument);

  useEffect(() => {
    const fetchExport = async () => {
      const exportResult = await getExportDocument({ caseId: caseId, extractDraftId: extractId, extractDraftExportId: extractExportId }).unwrap();

      if (exportResult) {
        setExportDocument(exportResult);
      } else {
        hubConnectionManager.startConnection(extractExportId);
        hubConnectionManager.on(
          extractExportId,
          ExtractDraftExportHubEventType.ExtractDraftExportEvent,
          (e: ExtractDraftExportEventModel) => {
            setIsProcessing(true);
            setProgressPercentage(e.progressPercentage);

            if (e.finished) {
              getExportDocument({ caseId: caseId, extractDraftId: extractId, extractDraftExportId: extractExportId }).unwrap().then((response) => setExportDocument(response));
              hubConnectionManager.stopConnection(extractExportId);
            } else if (e.failed) {
              setIsError(true);
              setIsProcessing(false);
            }
          });
      }
    };

    fetchExport();

    return () => {
      hubConnectionManager.stopConnection(extractExportId);
    };
  }, [caseId, extractExportId, extractId, getExportDocument, hubConnectionManager]);

  useEffect(() => {
    if (extractDocumentIsReady) {
      dispatch(baseApi.util.invalidateTags([
        apiTags.caseDocument,
      ]));
    }
  }, [dispatch, extractDocumentIsReady]);

  const circularProgressbarStyles: CircularProgressbarStyles = {
    text: {
      fill: "#000000",
      fontWeight: 300,
    },
    trail: {
      strokeLinecap: "round",
      stroke: "#f7f5f5",
    },
    path: {
      stroke: "#116c9b",
    },
  };


  return (
    <div className="d-flex flex-column h-100">
      <div className="subtle m-4">
        {extractDocumentIsReady ?
          <>
            <p>
              {localizer.completeExtractReadyHint1()}
            </p>
            <p>
              {localizer.completeExtractReadyHint2()}
            </p>
          </>
          :
          <>
            <p>
              {localizer.completeExtractHint1()}
            </p>
            <p>
              {localizer.completeExtractHint2()}
            </p>
          </>
        }
      </div>
      <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center margin-top-l">
        {extractDocumentIsReady ?
          <>
            <EmblaIllustration illustrationName="business-paper" color={IllustrationColor.Blue}/>

            <a
              className="margin-top-m"
              href={RoutePaths.caseDocument.url(caseId, exportDocument.caseDocumentId)}>
              <EmblaIcon iconName="link" color={IconColor.Blue}/>
              {exportDocument.caseDocumentName}
            </a>
          </>
          :
          isProcessing ?
            <div className={styles.progressBarContainer}>
              <CircularProgressbar
                value={progressPercentage}
                text={`${progressPercentage}%`}
                strokeWidth={4}
                styles={circularProgressbarStyles}
              />
            </div>
            :
            isError ?
              <div className="alert alert-danger" role="alert">
                {localizer.extractDraftProcessingError()}
              </div>
              :
              <Spinner />
        }
      </div>
    </div>
  );
};

