import classNames from "classnames";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Fragment, KeyboardEvent } from "react";
import { MarkingReplyModel, ReplyEditModel } from "@pages/pdfviewer/component/models/markingModel";
import useDateFormatter from "src/hooks/useDateFormatter";
import { ParticipantBadge } from "@components/case/participant/participantBadge/participantBadge";
import { useLocalization } from "@components/localization/localizationProvider";
import useValidatedForm from "@components/forms/useValidatedForm";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useGetEmployeeQuery } from "@services/api/employee/employeeApi";
import useMentionsHandler from "@components/mention/useMentionsHandler";
import { MentionTypeEnum } from "@components/mention/mentionModel";
import MentionRichTextArea from "@components/mention/mentionRichTextArea";
import ShowMentions from "@components/mention/showMentions";
import styles from "./markingReply.module.scss";

interface MarkingReplyProps {
  reply: MarkingReplyModel;
  isReplyOwner: boolean;
  isEditing: boolean;
  showEditBtn: boolean;
  profilePictureUrl?: string;
  onEditingChange: (editing: boolean) => void;
  onEdit: (editetReply: ReplyEditModel) => void
}

const MarkingReply = ({ reply, isReplyOwner, onEdit, isEditing, onEditingChange, profilePictureUrl, showEditBtn } : MarkingReplyProps) => {

  const dateFormatter = useDateFormatter();
  const localizer = useLocalization();

  const methods = useValidatedForm<ReplyEditModel>({
    defaultValues: {
      data: {
        content: reply.content,
        id: reply.id,
      },
    },
  });

  const { addMention, commitMentions } = useMentionsHandler(MentionTypeEnum.Reply);
  const { data: employee } = useGetEmployeeQuery(!reply.profilePictureUrl ?  reply.owner.employeeId : skipToken);

  const handleEdit = async (replyEditModel: ReplyEditModel) => {
    if (replyEditModel.data.content === reply.content) {
      onEditingChange(false);
    } else {
      onEdit(replyEditModel);
      await commitMentions(replyEditModel.data.id);
    }
  };

  const submitHandler =  methods.handleSubmit(handleEdit);

  const editOnKeyDownHandler = (e: KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      submitHandler();
    } else if (e.key === "Escape") {
      e.stopPropagation();
      methods.reset();
      onEditingChange(false);
    }
  };

  return (

    <div className={classNames(styles.replyCard)}>
      <div className="row align-items-center">
        <div className="col-6 d-flex align-items-center">
          <div className={classNames("flex-shrink-0", styles.markingIcon)}>
            <ParticipantBadge
              name={reply.owner.name}
              profilePictureUrl={profilePictureUrl ?? reply.profilePictureUrl ?? employee?.profilePictureUrl}
              hideNameField={true}
            />
          </div>
          <div className="margin-left-s">
            <div className={classNames("small", "margin-bottom-xs", "subtle", "text-nowrap")}>
              {reply.owner.name}
            </div>
            <p className="small margin-bottom-0">{dateFormatter.shortDateWithoutYearWithShortTime(reply.creationDate)}</p>
          </div>
        </div>
        <div className="col-6 d-flex flex-column gap-2">
          <div className="d-flex justify-content-end">
            {isReplyOwner && showEditBtn && <button
              className={classNames("btn btn-default btn-icon")}
              onClick={() => onEditingChange(true)}
            >
              <EmblaIcon iconName="edit" />
            </button>}
          </div>
        </div>
      </div>
      <div className="margin-top-s">
        {isEditing ?
          <form
            onSubmit={submitHandler}
          >
            <MentionRichTextArea
              autoFocus
              addMentionCallback={addMention}
              methods={methods}
              id={`reply-content-${reply.id}`}
              onKeyDown={editOnKeyDownHandler}
              placeholder={localizer.yourAnswer()}
              name="data.content"
              useAutoSizeOptions={{
                minRows: 1,
                maxRows: 10,
              }}
            />
          </form>
          : <div>
            <ShowMentions content={reply.content}/> {reply.hasBeenEdited && <span className="small subtle">({localizer.edited()})</span>}
          </div>}
      </div>
    </div>
  );


};

export default MarkingReply;
