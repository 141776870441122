import classNames from "classnames";
import { useEffect } from "react";
import Card from "@components/embla/card";
import FormRadio from "@components/forms/FormRadio";
import { useLocalization } from "@components/localization/localizationProvider";
import MarkingCloseBtn from "../shared/markingCloseBtn";
import { LineAppearance, LINE_COLORS, VerticalLinesMarkingModel } from "../../../models/markingModel";
import { EditCardProps } from "../shared/cardProps.model";
import ColorSelection from "../shared/colorSelection";
import MarkingMetaBlock from "../shared/markingMetaBlock";
import radioStyles from "../shared/radioGroup.module.scss";
import useMarkingCardForm from "../shared/useMarkingCardForm";
import MarkingTagsDropdown from "../shared/markingTagsDropdown";
import MarkingDeleteBtn from "../shared/markingDeleteBtn";
import styles from "./verticalLinesEdit.module.scss";

const lineColors = Object.values(LINE_COLORS);
const lineStyles = Object.values(LineAppearance)
  .map(Number).filter(Number.isFinite); // filter out numeric enum reverse mapped string

const VerticalLinesEdit = ({
  marking,
  onMarkingChange,
  onSave,
  onDelete,
  onClose,
  isTemplate,
}: EditCardProps<VerticalLinesMarkingModel>) => {
  const localizer = useLocalization();
  const markingCantBeDeleted = marking.presentationList && marking.presentationList.length > 0;

  const { methods, submitHandler } = useMarkingCardForm({
    marking,
    onMarkingChange,
    onSave,
    defaultValues: {
      tags: marking.tags,
      data: {
        color: marking.data.color,
        lines: marking.data.lines,
      },
    },
  });

  const { setFocus } = methods;
  useEffect(() => setFocus("data.color"), [setFocus]);

  return (
    <Card className={styles.container}>
      <Card.Header>
        {localizer.verticalLinesTitle()}
        <MarkingCloseBtn close={onClose} />
      </Card.Header>
      <Card.Body>
        <MarkingMetaBlock marking={marking} />
        <form onSubmit={submitHandler}>
          <div className={classNames(radioStyles.groupsBox, "rounded")}>
            <ColorSelection
              id="vertical-lines-color"
              formGroupClassName="margin-bottom-xs"
              methods={methods}
              name="data.color"
              colors={lineColors}
            />

            <div className={radioStyles.radioGroup}>
              {lineStyles.map((style) => (
                <label key={style}>
                  <FormRadio methods={methods} id={`vertical-lines-style-${style}`} name="data.lines" value={style}>
                    {style === LineAppearance.Brackets ? (
                      <div className={classNames(radioStyles.radio, styles.bracketsContainer, "rounded")}>
                        <div className={styles.Brackets} />
                      </div>
                    ) : (
                      <div className={classNames(radioStyles.radio, styles.lineStyle, "rounded", styles[LineAppearance[style]])} />
                    )}
                  </FormRadio>
                </label>
              ))}
            </div>
          </div>

          <MarkingTagsDropdown
            methods={methods}
            id={`vertical-lines-tags-${marking.id}`}
            name="tags"
          />
        </form>
      </Card.Body>
      {!isTemplate &&
        <Card.Footer>
          <MarkingDeleteBtn cantBeDeleted={markingCantBeDeleted ?? false} onDelete={onDelete} />
        </Card.Footer>
      }
    </Card>
  );
};

export default VerticalLinesEdit;

