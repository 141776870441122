import { NotificationModule } from "ditmer-embla";
import { useEffect, useState } from "react";
import { Row } from "react-table";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import { useDeletePresentationMutation } from "@services/api/casePresentation/casePresentationApi";
import { CasePresentationListModel } from "@services/api/casePresentation/models/casePresentationListModel";
import DropdownMenu, { MenuItemProps } from "@components/menuButton/dropdownMenu";
import { IconTextCallbackItem } from "@components/menuButton/dropdownMenuItems/IconTextDropdownItem";
import { MenuDotButton } from "@components/menuButton/dropdownMenuItems/menuDotButton";
import ModalMessage from "@components/modal/modalMessage";
import { useGetPresentationPagesQuery } from "@services/api/presentation/presentationApi";
import usePresent from "src/hooks/usePresent";
import useElectronApi from "src/hooks/useElectronApi";
import { useConfig } from "@components/config/configProvider";
import { RoutePaths } from "@components/routing/routes";
import useCaseAccess from "../hooks/useCaseAccess";
import { EditPresentation } from "./editPresentation/editPresentation";
import ExportPresentationModal from "./exportPresentationModal";
import SavePresentationForOfflineUseModal from "./savePresentationForOfflineUseModal";

const PresentationListRowActions = ({ caseId, row }: { caseId: string; row: Row<CasePresentationListModel> }) => {
  const localizer = useLocalization();
  const { canEditDeletePresentations } = useCaseAccess(caseId);
  const [deleteCasePresentation] = useDeletePresentationMutation();
  const { data: pages } = useGetPresentationPagesQuery(row.original.id);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalMessageOpen, setDeleteModalMessageOpen] = useState(false);
  const [showExportPresentationModal, setShowExportPresentationModal] = useState(false);
  const [showDownloadPresentationModal, setShowDownloadPresentationModal] = useState(false);

  const config = useConfig();
  const [ redirectAppUrl, setRedirectAppUrl ] = useState<string>();

  const present = usePresent({ presentationId:row.original.id, directToApp: redirectAppUrl });

  const electronApi = useElectronApi();

  useEffect(()=> {
    const url = (): string => `${config.appProtocol}:/${RoutePaths.casePresentationPresent.url(caseId, row.original.id)}`;
    setRedirectAppUrl(url());
  }, [caseId, config, row.original.id]);

  const presentClicked = () => {
    present.startPresentation();
  };

  const onDeletePresentation = (casePresentationId: string) => {
    if (!canEditDeletePresentations()) {
      return;
    }

    deleteCasePresentation({ caseId, presentationId: casePresentationId })
      .then(() => NotificationModule.showSuccessSmall(localizer.thePresentationWasDeleted()));
  };

  const addMenuItems = (): MenuItemProps[] => {
    const menuItems =  [{
      component: (
        <IconTextCallbackItem
          icon={<EmblaIcon iconName="edit" />}
          text={localizer.editPresentationTitle()}
          callback={() => setEditModalOpen(true)}
        />),
      key: localizer.editPresentation(),
      disabled: !canEditDeletePresentations(),
      disabledText: localizer.presentationEditDeleteNotAllowedDescription(),
    },
    {
      component: (
        <IconTextCallbackItem
          icon={<EmblaIcon iconName="document-pdf" />}
          text={localizer.exportPresentation()}
          callback={() =>  setShowExportPresentationModal(true)}
        />),
      key: localizer.exportPresentation(),
      disabled: (!pages || pages.length === 0),
      disabledText: localizer.presentationEmpty(),
    },
    {
      component: (
        <IconTextCallbackItem
          icon={<EmblaIcon iconName="expand" />}
          text={electronApi ? localizer.play() : localizer.playInApp()}
          callback={presentClicked}
        />),
      key: localizer.playInApp(),
      disabled: (!pages || pages.length === 0),
      disabledText: localizer.presentationEmpty(),
    },
    {
      component: (
        <IconTextCallbackItem
          icon={<EmblaIcon iconName="delete" />}
          text={localizer.deletePresentation()}
          callback={() =>  setDeleteModalMessageOpen(true)}
        />),
      key: localizer.deletePresentation(),
      disabled: !canEditDeletePresentations(),
      disabledText: localizer.presentationEditDeleteNotAllowedDescription(),
    },
    // todo 6350
    // { 
    //   component: (
    //     <IconTextCallbackItem
    //       icon={<EmblaIcon iconName="incoming" />}
    //       text={localizer.saveForOfflineUse()}
    //       callback={() => setShowDownloadPresentationModal(true)}
    //     />),
    //   key: localizer.saveForOfflineUse(),
    //   disabled: !electronApi,
    //   disabledText: localizer.presentationSaveDisabledHint(),
    // },
    ];

    return menuItems;
  };

  return (
    <div className="d-flex justify-content-center">
      {/* Prevent drag event on dropdownMenu */}
      <div draggable="true" onDragStart={(e) => { e.stopPropagation(); e.preventDefault();}}>
        <DropdownMenu
          buttonNode={<MenuDotButton/>}
          menuItems={addMenuItems()}/>
      </div>
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        size={ModalSizeEnum.Small}
      >
        <EditPresentation
          caseId={caseId}
          presentationId={row.original.id}
          title={row.original.title}
        />
      </Modal>
      <ModalMessage
        open={deleteModalMessageOpen}
        onClose={() => setDeleteModalMessageOpen(false)}
        title={localizer.areYouSure()}
        description={localizer.areYouSurePresentationDelete()}
        modalAcceptType="danger"
        acceptButtonText={localizer.delete()}
        acceptAction={() => deleteModalMessageOpen && onDeletePresentation(row.original.id)}
      />
      {row.original.id && <ExportPresentationModal
        openModal={showExportPresentationModal}
        onCloseCallback={()=>setShowExportPresentationModal(false)}
        caseId={caseId}
        presentationId={row.original.id}
        presentationTitle={row.original.title}
      />}
      {row.original.id && <SavePresentationForOfflineUseModal
        openModal={showDownloadPresentationModal}
        onCloseCallback={() => setShowDownloadPresentationModal(false)}
        caseId={caseId}
        presentationId={row.original.id}
        presentationTitle={row.original.title}
      />}
    </div>
  );
};

export default PresentationListRowActions;
