import { useLocalization } from "@components/localization/localizationProvider";
import { RoutePaths } from "@routes";

const GenericErrorPage = (props : { message? : string, sentryErrorKey?: string }) => {
  const localizer = useLocalization();

  return (
    <div className="body-container">
      <div className="page-content">
        <div className="main-content">
          <div className="body-content">
            <h1>{localizer.errorOccuredTitle()}</h1>
            <div className="mt-2">
              <p>{props.message ?? localizer.errorOccuredContent()}</p>
              {props.sentryErrorKey &&
                <p>{localizer.errorCode()} {props.sentryErrorKey}</p>
              }
            </div>
            <button onClick={() => window.location.href = RoutePaths.index.url} className="btn btn-primary">{localizer.backToIndex()}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericErrorPage;
