import { useRef, useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import Card from "@components/embla/card";
import InlineSwitch from "@components/checkbox/inlineSwitch";
import { useLocalization } from "@components/localization/localizationProvider";
import { useOnClickOutside } from "@pages/pdfviewer/component/hooks/useClickOutside";
import { useChangeIsPageNumberCoordinatesAutonomousMutation } from "@services/api/document/caseDocumentApi";
import { ChangeIsCoordinateAutonomousModel } from "@services/api/document/models/pageNumberCoordinates";
import styles from "./PageNumber.module.scss";
import { usePageNumberDnd } from "./hooks/usePageNumberDnd";

type PageNumberProps = {
  number: number;
  x: number;
  y: number;
  scale: number;
  isAutonomousCoordinates: boolean;
};

const PageNumber = ({ number, x, y, scale, isAutonomousCoordinates = false }: PageNumberProps) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const numberElRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(numberElRef, () => setIsPopoverVisible(false));

  const { caseId, documentId } = useParams();
  const localization = useLocalization();

  const [changeIsAutonomousCoordinate] = useChangeIsPageNumberCoordinatesAutonomousMutation();
  const { dropBoundaryEl, numberPosition, pageNumberClassName, onPointerDown, isLoading } = usePageNumberDnd({
    scale,
    numberElRef,
    canDrag: true,
    numberXY: { x, y },
    number,
    caseId,
    documentId,
    moveOnlyThisNumber: isAutonomousCoordinates,
  });
  if (!caseId || !documentId) {
    return null;
  }

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const model: ChangeIsCoordinateAutonomousModel = {
      caseDocumentId: documentId,
      isAutonomousCoordinates: checked,
      number,
    };
    changeIsAutonomousCoordinate({ caseId: caseId, model });
  };

  return (
    <>
      <div
        onDragStart={() => setIsPopoverVisible(false)}
        onPointerDown={onPointerDown}
        onClick={() => setIsPopoverVisible(true)}
        style={{ left: numberPosition.x, top: numberPosition.y }}
        className={classNames(pageNumberClassName, styles.container, isLoading && styles.disabled)}
        ref={numberElRef}
      >
        {number}
        {isPopoverVisible && (
          <Card className={styles.popover}>
            <p>{localization.pageNumber()}</p>
            <div className="d-flex gap-8">
              <InlineSwitch checked={isAutonomousCoordinates} onChange={handleSwitchChange} />
              <p>{localization.seperatePageNumberPlacement()}</p>
            </div>
          </Card>
        )}
      </div>
      {dropBoundaryEl}
    </>
  );
};

export default PageNumber;

