import { useDrop } from "react-dnd";
import { useNavigate } from "react-router-dom";
import { NotificationModule } from "ditmer-embla";
import classNames from "classnames";
import { useEffect } from "react";
import { CasePresentationPageModel, SpeakerNoteSizeEnum } from "@services/api/case/models/casePresentationPageModel";
import { DragNDrop } from "@components/dnd/dragNDrop";
import { useLocalization } from "@components/localization/localizationProvider";
import useCaseAccess from "@pages/case/hooks/useCaseAccess";
import { PresentationInfo } from "@pages/pdfviewer/component/models/presentationInfo";
import { useBulkAddToPresentationsMutation, useRemovePresentationPageMutation } from "@services/api/casePresentation/casePresentationApi";
import ExpandingDropdownMenu from "@components/expandingDropdownMenu/expandingDropdownMenu";
import ThumbnailContainer from "@pages/pdfviewer/component/thumbnail/thumbnailContainer";
import { IconTextCallbackItem } from "@components/menuButton/dropdownMenuItems/IconTextDropdownItem";
import { RoutePaths } from "@components/routing/routes";
import { EmblaIcon } from "@components/embla/emblaIcon";
import styles from "./PageContainer.module.scss";

type PagesContainerProps = {
  page: CasePresentationPageModel;
  caseId: string;
  activePresentation?: PresentationInfo;
  setPages: React.Dispatch<React.SetStateAction<CasePresentationPageModel[]>>;
  presentationMode?: boolean;
  activePageId?: string | { index: number; caseDocumentId?: string };
  onPageRemoved?: (pageId: string) => void;
  index: number;
  reorder: ( droppedIndex: number ) => void;
  pageTitle?: string;
  setIsLoadingNewPage: (isLoading: boolean) => void;
};
const PageContainer = ({ page, caseId, activePresentation, setPages, presentationMode, activePageId, onPageRemoved, index, reorder, pageTitle, setIsLoadingNewPage }: PagesContainerProps) => {
  const navigate = useNavigate();
  const localizer = useLocalization();

  const [removePresentationPage] = useRemovePresentationPageMutation();
  const [bulkAddToPresentations, { isLoading }] = useBulkAddToPresentationsMutation();

  const { canEditDeletePresentations } = useCaseAccess(caseId);

  const reorderPreview = (draggedItemIndex: number, hoverIndex: number) => {
    setPages((prev) => {
      const pagesToReorder = [...prev];
      pagesToReorder.move(draggedItemIndex, hoverIndex, pagesToReorder[draggedItemIndex]);
      return pagesToReorder;
    });
  };

  useEffect(()=> {
    setIsLoadingNewPage(isLoading);
  }, [isLoading, setIsLoadingNewPage]);

  const dummyPageId = `dummy ${index}`;

  const [{ isOver }, drop] = useDrop({
    accept: "page",
    drop: (dropped: { documentId: string; index: number }) => {
      if (activePresentation) {
        bulkAddToPresentations({
          caseId,
          model: {
            documentId: dropped.documentId,
            presentations: [{ id: activePresentation.id, order: index }],
            pageIndexes: [dropped.index],
          },
        });
      }
    },
    hover: (item: { documentId: string; index: number }) => {
      if (page.id !== dummyPageId) {
        setPages((prev) => {
          let newPages = [...prev];
          newPages = newPages.filter((p) => !p.id.includes("dummy"));
          const newPagePreviewItem: CasePresentationPageModel = {
            caseDocumentId: item.documentId,
            fileName: "",
            id: dummyPageId,
            hasDocumentMarkings: false,
            isTitlePage: false,
            pageIndex: 1,
            markingIds: [],
            order: 1,
            overlayIconTypes: [],
            tags: [],
            caseId: "",
            notes: [],
            noteSize: SpeakerNoteSizeEnum.Small,
          };
          newPages.splice(index, 0, newPagePreviewItem);
          return newPages;
        } );
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} >
      <DragNDrop
        index={index}
        dndType="presentationPage"
        onDropRow={(droppedIndex) => reorder(droppedIndex)}
        onPreviewDropRow={(draggedItemIndex, hoverIndex) => reorderPreview(draggedItemIndex, hoverIndex)}
        disableDrag={!canEditDeletePresentations()}
      >
        <div className="d-flex flex-column align-items-center">
          <ExpandingDropdownMenu
            triggeredBy={"rightclick"}
            menuDropdownPlacement={"right"}
            buttonNode={
              <div
                onClick={() => {
                  if (!activePresentation) {
                    return;
                  }
                  navigate(RoutePaths.casePresentationEdit.url(caseId, activePresentation.id, page.id) + "?back=doc", { replace: presentationMode });
                }}
              >
                <ThumbnailContainer
                  srcUrl={page.thumbnailUrl}
                  fileName={page.fileName}
                  title={page.isTitlePage ? pageTitle : page.fileName}
                  overlayIcons={page.overlayIconTypes}
                  active={
                    typeof activePageId === "string"
                      ? activePageId === page.id
                      : activePageId?.index === page.pageIndex && activePageId.caseDocumentId === page.caseDocumentId
                  }
                />
              </div>
            }
          >
            <ExpandingDropdownMenu.LinkItem
              to={RoutePaths.caseDocumentPage.url(caseId, page.caseDocumentId, page.pageIndex + 1) + "?back=-1"}
              key={localizer.goToPageInDocument()}
              state={{ navigateToPageIndex: page.pageIndex + 1 }}
            >
              <IconTextCallbackItem icon={<EmblaIcon iconName="forward" />} text={localizer.goToPageInDocument()} />
            </ExpandingDropdownMenu.LinkItem>
            <ExpandingDropdownMenu.ButtonItem
              disabled={!canEditDeletePresentations()}
              disabledText={localizer.presentationEditDeleteNotAllowedDescription()}
              key={localizer.removeFromPresentation()}
              onClick={() =>
                removePresentationPage({ caseId: caseId, presentationPageId: page.id, presentationId: activePresentation?.id ?? "" }).then(() => {
                  NotificationModule.showSuccessSmall(localizer.pageGotRemoved());
                  onPageRemoved?.(page.id);
                })
              }
            >
              <IconTextCallbackItem icon={<EmblaIcon iconName="delete" />} text={localizer.removeFromPresentation()} />
            </ExpandingDropdownMenu.ButtonItem>
          </ExpandingDropdownMenu>

          <p className={classNames("small margin-top-xs margin-bottom-0", styles.pageTitle)} title={pageTitle}>
            {page.isTitlePage ? localizer.presentationTitlePage() : pageTitle}
          </p>
        </div>
      </DragNDrop>
    </div>
  );
};

export default PageContainer;
