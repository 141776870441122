import classNames from "classnames";
import { BadgeSize } from "@components/badge/roundBadge";

import { DocumentPageOverlayIconTypeEnum } from "../../models/documentPageOverlayIconType";
import ThumbnailIcon from "../thumbnailIcon";
import styles from "./thumbnailOverlay.module.scss";

type ThumbnailOverlayProps = {
  iconTypes: DocumentPageOverlayIconTypeEnum[];
  badgeSize?: BadgeSize;
  hidePresentationIcon?: boolean;
  alignRight?: boolean;
};

const ThumbnailOverlay = ({ iconTypes, badgeSize, hidePresentationIcon, alignRight }: ThumbnailOverlayProps) => {

  const filteredIconTypes = !hidePresentationIcon
    ? iconTypes
    : iconTypes.filter((x) => x !== DocumentPageOverlayIconTypeEnum.Presentation);

  return (
    <div
      className={classNames(
        styles.thumbnailIcons,
        alignRight && styles.alignRight,
      )}
    >
      {filteredIconTypes.map((iconType, index) => <ThumbnailIcon key={index} iconType={iconType} badgeSize={badgeSize ?? BadgeSize.ExtraSmall} />)}
    </div>
  );
};

export default ThumbnailOverlay;
