import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@components/button/IconButton/IconButton";
import FormInput from "@components/forms/FormInput";
import useValidatedForm from "@components/forms/useValidatedForm";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import { ExtractDraftSectionDocumentModel } from "@services/api/extractDraft/models/extractDraftSectionDocumentModel";
import FormDatePicker from "@components/forms/formDatePicker";
import FormSwitch from "@components/forms/formSwitch";
import { useLocalization } from "@components/localization/localizationProvider";
import FormRadio from "@components/forms/FormRadio";
import Button from "@components/embla/button";
import { useDeleteDocumentFromSectionExtractDraftMutation, useUpdateExtractDraftDocumentMutation } from "@services/api/extractDraft/extractDraftApi";
import { ExtractDraftSectionDocumentUpdateModel, draftSectionDocumentUpdateValidation } from "@services/api/extractDraft/models/extractDraftSectionDocumentUpdateModel";
import { IntervalExtractionType } from "@components/case/extractDraft/addDocumentsToDraftModel";
import useAutosave from "src/hooks/useAutosave";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { RoutePaths } from "@components/routing/routes";
import PageIntervals from "@pages/extractCompositionPage/components/PageIntervals/PageIntervals";
import FormDropdown from "@components/forms/FormDropdown";
import {
  convertDateTypeToString,
  DateTypeEnum,
  getDateTypeDropdownOptions,
  getDateTypeTypes,
} from "@services/api/case/models/dateTypeEnum";
import styles from "./EditDocumentModal.module.scss";
import VerticalLinesDisplay from "./VerticalLinesDisplay/VerticalLinesDisplay";

type DocumentModalProps = {
  open: boolean,
  onClose: () => void,
  document: ExtractDraftSectionDocumentModel,
  caseId: string,
  extractDraftId: string,
  sectionId: string,
};

const EditDocumentModal = ({ open, onClose, document, caseId, extractDraftId, sectionId } : DocumentModalProps) => {
  const formFieldIdPrepend = "edit-document";

  const [ updateDocument, updateDocumentRequest ] = useUpdateExtractDraftDocumentMutation();
  const [ removeDocument ] = useDeleteDocumentFromSectionExtractDraftMutation();

  const navigate = useNavigate();
  const localizer = useLocalization();

  const defaultValues : ExtractDraftSectionDocumentUpdateModel = {
    caseDocumentId: document.caseDocumentId,
    caseReferenceNumber: document.caseDocumentNumber ? document.caseDocumentNumber.toString() : "",
    date: document.caseDocumentDate,
    id: document.id,
    intervalExtractionType: document.intervalExtractionType,
    title: document.caseDocumentName,
    includeInToC: document.includeInToC,
    includeMarkings: document.includeMarkings,
    pageIntervals: document.pageIntervals.map((interv) => `${interv.firstPageIndex + 1}-${interv.lastPageIndex + 1}`).join(", "),
    dateType: document.caseDocumentDateType,
  };

  const methods = useValidatedForm({
    defaultValues,
    validationSchema: draftSectionDocumentUpdateValidation(),
  });
  const watchedPagesExtractOptions = methods.watch("intervalExtractionType");

  const { getFieldId } = useAutosave({
    queryStatus: updateDocumentRequest.status,
    watch: methods.watch,
    fieldIdPrefix: formFieldIdPrepend,
    onChange: (field, value) => {
      if (field === "intervalExtractionType" && value !== IntervalExtractionType.AllDocument) {
        methods.setValue("pageIntervals", `1-${document.pageCount}`);
      }
    },
    onSubmit: methods.handleSubmit(async (model) => {
      await updateDocument({
        caseId,
        extractDraftId,
        model,
      }).unwrap();
    }),
  });

  const handleRemoveDocument = () => {
    removeDocument({
      documentId: document.id,
      caseId,
      extractDraftId,
      sectionId,
    }).unwrap()
      .then(() => {
        onClose();
      });
  };

  return <Modal className="p-3" size={ModalSizeEnum.Large} onClose={onClose} open={open}>
    <div className="d-flex justify-content-between p-4 border-bottom">
      <h3>{document.caseDocumentName}</h3>
      <IconButton iconName="close" onClick={onClose}/>
    </div>
    <div className={classNames(styles.formContainer, styles.padding)}>
      <FormInput
        label={localizer.title()}
        id={getFieldId("title")}
        methods={methods}
        name="title"/>
      <FormDropdown
        methods={methods}
        id={getFieldId("dateType")}
        name="dateType"
        options={getDateTypeDropdownOptions(localizer, getDateTypeTypes())}
        valueToOptionTransform={(value: DateTypeEnum) => value ? { label: convertDateTypeToString(value, localizer), value: value } : null}
        label={localizer.dateType()}
        formGroupClassName={`${styles.formGroup} ${styles.formGroupEditDocumentMinWidth}`}
        required
      />

      <FormInput
        label={localizer.annex()}
        id={getFieldId("caseReferenceNumber")}
        methods={methods}
        name="caseReferenceNumber"
      />
      <FormDatePicker
        methods={methods}
        name="date"
        id={getFieldId("date")}
        label={localizer.date()}
      />
      <FormSwitch id={getFieldId("includeInToC")} methods={methods} name="includeInToC" label={localizer.showInTableOfContents()}/>
      <FormSwitch id={getFieldId("includeMarkings")} methods={methods} name="includeMarkings" label={localizer.includeMarkingsOnExport()}/>
      <div>
        <h5>{localizer.pagesIncludedInExtract()}</h5>
        <FormRadio
          id={`radio-all-${document.id}`}
          methods={methods}
          name="intervalExtractionType"
          label={localizer.wholeDocument()}
          value={IntervalExtractionType.AllDocument}
        />
        <FormRadio
          id={`radio-define-${document.id}`}
          methods={methods}
          name="intervalExtractionType"
          label={localizer.definePageIntervals()}
          value={IntervalExtractionType.DefinedPages}
        />
        <FormRadio
          id={`radio-vertical-lines-${document.id}`}
          methods={methods} name="intervalExtractionType"
          label={localizer.pagesWithVerticalLines1()}
          value={IntervalExtractionType.PagesWithVerticalLines}
        />
      </div>
      <div className={classNames("align-self-center")
      }>
        {
          watchedPagesExtractOptions === IntervalExtractionType.DefinedPages &&  <>
            <FormInput id={getFieldId("pageIntervals")} methods={methods} name="pageIntervals"/>
            <p className="m-0">
              <EmblaIcon additionalClasses="mr-1" iconName="info"/>
              {localizer.pageIntervalInfoMessage()}
            </p>
          </>
        }
        {
          watchedPagesExtractOptions === IntervalExtractionType.PagesWithVerticalLines &&
          <VerticalLinesDisplay caseId={caseId} documentId={document.caseDocumentId}/>
        }
      </div>
    </div>
    <PageIntervals caseId={caseId} pageIntervals={document.pageIntervals}/>
    <div className={classNames(styles.padding, styles.marginTop, styles.paddingBottom )} >
      <div className={classNames(styles.paddingTop, "border-top d-flex justify-content-between")} >
        <Button onClick={() => navigate(RoutePaths.caseDocumentPage.url(caseId, document.caseDocumentId, 1) + `/?back=hbb&id=${extractDraftId}`)}>{localizer.goToDocument()}</Button>
        <div className="d-flex gap-8">
          <Button onClick={handleRemoveDocument} style={{ color: "#f9736d" }}>{localizer.removeFromExtract()}</Button>
        </div>
      </div>
    </div>
  </Modal>;
};

export default EditDocumentModal;
