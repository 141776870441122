import { Divider } from "antd";
import { useLocalization } from "@components/localization/localizationProvider";
import { CasePresentationPageModel } from "@services/api/case/models/casePresentationPageModel";
import MarkdownRenderer from "@components/markdown/markdownRenderer";
import MarkdownSizeButtons from "@components/markdown/markdownSizeButtons";

interface PresentSidebarProps {
  pagesData: CasePresentationPageModel[];
  currentPageIndex: number;
  presentationId: string;
}

const PresentSidebar = ({
  pagesData,
  currentPageIndex,
  presentationId,
}: PresentSidebarProps) => {
  const localizer = useLocalization();
  const currentPage = pagesData[currentPageIndex];

  return (
    <div className="h-100 py-4">
      <div className="relative h-100 d-flex flex-column align-items-start gap-m">
        <div className="d-flex flex-column w-100 h-100">
          <div className="d-flex justify-content-between">
            <div><h3 className="px-2">{localizer.speakerNotes()}</h3></div>
            <div className="d-flex gap-s mx-2">
              <MarkdownSizeButtons presentationId={presentationId} caseId={currentPage.caseId} presentationPageId={currentPage.id} noteSize={currentPage.noteSize}/>
            </div>
          </div>
          <Divider className="w-100"/>
          <div className="px-2 w-100 overflow-auto scrollbar word-wrap">
            {currentPage?.notes.map((note) => (
              <div key={note.id}>
                <div className="w-100">
                  <MarkdownRenderer size={currentPage.noteSize}>{note.note}</MarkdownRenderer>
                </div>
                <Divider className="my-3"/>
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
};

export default PresentSidebar;
