import classNames from "classnames";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import { IconButton } from "@components/button/IconButton/IconButton";
import { useLocalization } from "@components/localization/localizationProvider";
import { useGetTableOfContentsUrlExtractDraftQuery, useUpdateExtractDraftFrontpageSettingsMutation, useUpdateTableOfContentsExtractDraftMutation } from "@services/api/extractDraft/extractDraftApi";
import FormSwitch from "@components/forms/formSwitch";
import useAutosave from "src/hooks/useAutosave";
import { UpdateCaseExtractDraftTableOfContentsModel } from "@services/api/extractDraft/models/UpdateCaseExtractDraftTableOfContentsModel";
import { ExtractDraftTableOfContentsModel } from "@services/api/extractDraft/models/extractDraftTableOfContentsModel";
import useValidatedForm from "@components/forms/useValidatedForm";
import FormDropdown from "@components/forms/FormDropdown";
import { DateFormatEnum, convertDateFormatEnumToString, getDateFormatEnum, getDateFormatEnumDropdownOptions } from "@services/api/extractDraft/models/dateFormatEnum";
import useElementSize from "src/hooks/useElementSize";
import { ExtractDraftFrontpageModel } from "@services/api/extractDraft/models/extractDraftFrontpageModel";
import { FrontpageSettingsModel } from "@services/api/extractDraft/models/frontpageSettingsModel";
import { MultiPagePdfView } from "../../MultiPagePdfView/MultiPagePdfView";
import styles from "./TableOfContents.module.scss";

type TableOfContentsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  extractDraftId: string;
  caseId: string;
  tableOfContents: ExtractDraftTableOfContentsModel;
  frontpage: ExtractDraftFrontpageModel;
};

const TableOfContentsModal = ({ isOpen, onClose, caseId, extractDraftId, tableOfContents, frontpage } : TableOfContentsModalProps) => {
  const localizer = useLocalization();
  const formFieldIdPrepend = "extractDetails-toolbar";
  const formFieldIdPrependFrontpage = "extractDetails-frontpage";
  const [containerRef, containerSize] = useElementSize();

  const [updateTableOfContents, updateTableOfContentsRequest] = useUpdateTableOfContentsExtractDraftMutation();
  const [updateFrontpageSettings, updateFrontpageRequest] = useUpdateExtractDraftFrontpageSettingsMutation();

  const defaultValuesTOC: UpdateCaseExtractDraftTableOfContentsModel =  {
    repeatableColumns: tableOfContents.repeatableColumns,
    generate: tableOfContents.generate,
    insertSectionDividers: tableOfContents.insertSectionDividers,
    showDates: tableOfContents.showDates,
    showOwnPageNumber: tableOfContents.showOwnPageNumber,
    showLeader: tableOfContents.showLeader,
    showPageNumbersAsIntervals: tableOfContents.showPageNumbersAsIntervals,
    dateFormatEnum: tableOfContents.dateFormatEnum,
    documentsStartOnOddPages: tableOfContents.documentsStartOnOddPages,
    alternatingPageNumbers: tableOfContents.alternatingPageNumbers,
  };

  const defaultValuesFrontpage: FrontpageSettingsModel = {
    showOwnPageNumber: frontpage.showOwnPageNumber,
  };

  const methodsTOC = useValidatedForm({
    defaultValues: defaultValuesTOC,
  });

  const methodsFrontpage = useValidatedForm({
    defaultValues: defaultValuesFrontpage,
  });

  const { getFieldId } = useAutosave({
    queryStatus: updateTableOfContentsRequest.status,
    fieldIdPrefix: formFieldIdPrepend,
    watch: methodsTOC.watch,
    displayStatus: false,
    onSubmit: methodsTOC.handleSubmit((model) => {
      updateTableOfContents({ caseId: caseId, extractDraftId, model }).unwrap().catch((e) => {
        methodsTOC.reset(defaultValuesTOC);
      });
    }),
  });

  const { getFieldId: getFieldIdFrontpage } = useAutosave({
    queryStatus: updateFrontpageRequest.status,
    fieldIdPrefix: formFieldIdPrependFrontpage,
    watch: methodsFrontpage.watch,
    displayStatus: false,
    onSubmit: methodsFrontpage.handleSubmit((model) => {
      updateFrontpageSettings({ caseId: caseId, extractDraftId, model }).unwrap().catch((e) => {
        methodsFrontpage.reset(defaultValuesFrontpage);
      });
    }),
  });

  const { data: tableOfContentsUrl } = useGetTableOfContentsUrlExtractDraftQuery({ caseId: caseId, extractDraftId: extractDraftId });
  return (
    <Modal size={ModalSizeEnum.ExtraLarge} open={isOpen} onClose={onClose} className="p-3">
      <div className="d-flex justify-content-between p-4 border-bottom">
        <h3>{localizer.tableOfContents()}</h3>
        <IconButton iconName="close" onClick={onClose}/>
      </div>
      <div className="container p-3 border-bottom">
        <div className="row">
          <FormSwitch
            methods={methodsTOC}
            name={"showLeader"}
            id={getFieldId("showLeader")}
            label={localizer.showLeader()}
            formGroupClassName="col-4"
            disabled={!defaultValuesTOC.generate}
          />
          <FormSwitch
            methods={methodsTOC}
            name={"showDates"}
            id={getFieldId("showDates")}
            label={localizer.showDates()}
            formGroupClassName="col-4"
          />
          <FormSwitch
            methods={methodsTOC}
            name={"showOwnPageNumber"}
            id={getFieldId("showOwnPageNumber")}
            label={localizer.tableOfContentsShowPageNumber()}
            formGroupClassName="col-4"
          />
        </div>
        <div className="row">
          <FormSwitch
            methods={methodsTOC}
            name={"insertSectionDividers"}
            id={getFieldId("insertSectionDividers")}
            label={localizer.insertSectionDivider()}
            formGroupClassName="col-4"
          />
          <FormSwitch
            methods={methodsTOC}
            name={"showPageNumbersAsIntervals"}
            id={getFieldId("showPageNumbersAsIntervals")}
            label={localizer.showPageNumbersAsIntervals()}
            formGroupClassName="col-4"
          />
          <FormSwitch
            methods={methodsTOC}
            name={"repeatableColumns"}
            id={getFieldId("repeatableColumns")}
            label={localizer.showColumnsOnAllPages()}
            formGroupClassName="col-4"
          />
        </div>
        <div className="row">
          <FormSwitch
            methods={methodsTOC}
            name={"documentsStartOnOddPages"}
            id={getFieldId("documentsStartOnOddPages")}
            label={localizer.documentsStartOnOddPages()}
            formGroupClassName="col-4"
          />
          <FormSwitch
            methods={methodsTOC}
            name={"alternatingPageNumbers"}
            id={getFieldId("alternatingPageNumbers")}
            label={localizer.alternatingPageNumbers()}
            formGroupClassName="col-4"
          />
          <FormSwitch
            methods={methodsFrontpage}
            name="showOwnPageNumber"
            id={getFieldIdFrontpage("showOwnPageNumber")}
            label={localizer.frontpageShowPageNumber()}
            formGroupClassName="col-4"
          />
          <FormDropdown
            labelClassName={styles.alignLeft}
            label={localizer.dateformat()}
            methods={methodsTOC}
            id={getFieldId("dateFormatEnum")}
            name="dateFormatEnum"
            options={getDateFormatEnumDropdownOptions(getDateFormatEnum())}
            valueToOptionTransform={(value: DateFormatEnum) => value ? { label: convertDateFormatEnumToString(value), value: value } : null}
            formGroupClassName={`${styles.formGroup} ${styles.formGroupEditDocumentMinWidth} col-4 d-flex align-items-center gap-m`}
          />
        </div>
      </div>
      <div ref={containerRef} className={classNames(styles.fullSizeScrollable, "p-3 background-gray") }>
        {tableOfContentsUrl &&
          <MultiPagePdfView
            containerSize={containerSize}
            margins={20}
            url={tableOfContentsUrl}
            pageGap={10}
          />
        }
      </div>
    </Modal>
  );
};

export default TableOfContentsModal;

