import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocalization } from "@components/localization/localizationProvider";
import { Spinner } from "@components/spinner/spinner";
import { AccessRight } from "@infrastructure/AccessRight";
import { useAuth } from "@components/auth/authProvider";
import { SessionStorageKeys } from "@infrastructure/sessionStorageKeys";


export const RequireAuth = ({ children, requiredAccessRight }: { children: JSX.Element, requiredAccessRight?: AccessRight }) => {
  const [loginRedirectFailed, setLoginRedirectFailed] = useState(false);

  const localizer = useLocalization();
  const location = useLocation();
  const auth = useAuth();

  let { authenticated } = auth;
  const { userService } = auth;

  if (authenticated && requiredAccessRight) {
    authenticated = auth.user.hasAccessRight(requiredAccessRight);
  }

  useEffect(() => {
    if (!authenticated) {
      sessionStorage.setItem(SessionStorageKeys.returnUrl, location.pathname);
      userService.login().catch((e) => {
        setLoginRedirectFailed(true);
        throw e;
      });
    }
  }, [authenticated, location.pathname, userService]);


  return authenticated
    ? children
    : (
      <div className="d-flex flex-column align-items-center margin-top-xl">
        {loginRedirectFailed
          ? <h4>{localizer.connectionFailed()}</h4>
          : <Spinner text={localizer.startingConnection()} />
        }
      </div>
    );
};
