import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "@hooks";
import { pdfTypeIdSelector } from "@pages/pdfviewer/component/pdfViewerSlice";
import { useGetDocumentPagesQuery } from "@services/api/document/caseDocumentApi";

export const usePageNumbers = (pageIndex: number) => {
  const documentId = useAppSelector(pdfTypeIdSelector);
  const { caseId } = useParams();

  const { data: pages, isLoading } = useGetDocumentPagesQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { caseId: caseId!, documentId: documentId! },
  );
    
  const allPageNumbers = useMemo(() => {
    return pages?.map((x) => x.pageNumber);
  }, [pages]);

  const pageNumber = useMemo(() => {
    return allPageNumbers?.[pageIndex];
  }, [allPageNumbers, pageIndex]);
  
  return {
    pageNumber,
    isLoading,
  };
};