import {
  createContext, useContext, useEffect, useMemo, useState,
} from "react";
import { useAppDispatch, useAppSelector } from "@hooks";
import { User } from "@models/User";
import { useGetUserInfoQuery } from "@services/api/user/userApi";
import { UserService } from "@services/userService";
import useElectronApi from "src/hooks/useElectronApi";
import { StorageKeys } from "@infrastructure/storageKeys";
import {
  authStateSelector, setToken, setUser,
} from "./authSlice";


interface AuthContextType {
  authenticated: boolean;
  user: User;
  signOut: () => void;
  impersonate: (userId: string) => void;
  stopImpersonation: () => void;
  userService: UserService;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used in AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ userService: UserService }> = ({ children, userService }) => {
  const dispatch = useAppDispatch();
  const [initialized, setInitialized] = useState(false);
  const electronApi = useElectronApi();
  const {
    token, // can come from userService in effect below or AuthRedirectPage
    user: userFromStore,
  } = useAppSelector(authStateSelector);
  const { data: userInfoResult, error: userInfoError } = useGetUserInfoQuery(undefined, { skip: !token });

  useEffect(() => {
    const handler = () => {
      if (navigator.onLine) {
        return userService.logout();
      }
      return Promise.resolve();
    };
    userService.addSilentRenewErrorHandler(handler);
    return () => userService.removeSilentRenewErrorHandler(handler);
  }, [userService]);

  useEffect(() => {
    if (!token && !initialized) {
      userService.getUserToken()
        .then((accessToken) => {
          if (accessToken)
            dispatch(setToken(accessToken));
          else
            setInitialized(true);
        });
    }
  }, [dispatch, token, initialized, userService]);


  useEffect(() => {
    if (userInfoResult) {
      dispatch(setUser(userInfoResult));
      setInitialized(true);
    }
  }, [dispatch, userInfoResult]);

  useEffect(() => {
    if (userInfoError) {
      userService.logout();
    }
  }, [userService, userInfoError]);

  const authProviderValue = useMemo(() => {
    const user = new User(userFromStore);
    return {
      signOut: () => userService.logout(),
      impersonate: (userId: string) => userService.impersonate(userId),
      stopImpersonation: () => userService.stopImpersonate(),
      authenticated: user.authenticated,
      user,
      userService: userService,
    };

  }, [userFromStore, userService]);

  if (!initialized)
    return null;

  return (
    <AuthContext.Provider value={authProviderValue}>
      {children}
    </AuthContext.Provider>
  );
};
